import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import {Link} from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'

class NewsletterSubscriptionThankyou extends React.Component{
    render(){
        return(
            <>
            <ScrollToTop />
            <Layout transparent={false}>
                <TemplateOneSection>
                    <ThemeHeading>
                        <h1>SUBSCRIBED SUCCESSFULLY</h1>
                    </ThemeHeading>
                    <p>Thank You for subscribing to our newsletter. Stay tuned for some news heading your way.</p>
                    <Link to="/" className="_button">Go To Home Page</Link>
                </TemplateOneSection>
            </Layout>
            </>
        )
    }
}

export default NewsletterSubscriptionThankyou