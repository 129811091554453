import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import TabsSection from '../ui/TabsSection'
import SplitColumnSection from '../ui/SplitColumnSection'
import MasonaryGridBlog from '../ui/MasonaryGridBlog'
import GetInTouch from '../ui/GetInTouch'
import {connect} from 'react-redux'
import {
    fetchPublicProductDetails
} from '../../actions'
import {BASE_PATH} from '../../paths'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'

class Rugs extends React.Component{

    componentDidMount(){
        this.props.fetchPublicProductDetails(this.props.match.params.slug)
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.slug !== prevProps.match.params.slug){
            this.props.fetchPublicProductDetails(this.props.match.params.slug)
        }
    }

    renderContent(){

        const {size, colour, texture, pattern} = this.props.product

        switch (this.props.product.slug) {

            case "designer-collaborations":
                return (
                    <ScrolledIntoView>
                        <div className="_blockquote _center" style={{maxWidth: '970px'}}>
                            <h2><i>“We believe in the power of collaboration. We aim to inspire by weaving the world’s most beautiful rugs in new modern and timeless designs.”</i></h2>
                        </div>
                    </ScrolledIntoView>
                )
        
            default:
                return (
                    <>
                        <div className="_column _column-6 _center _size">
                            <ScrolledIntoView>
                                <ThemeHeading>
                                    <h2>SIZE</h2>
                                </ThemeHeading>
                                <div dangerouslySetInnerHTML={{__html: size}} />
                            </ScrolledIntoView>
                        </div>
                        <div className="_column _column-6 _center _size">
                            <ScrolledIntoView>
                                <ThemeHeading>
                                    <h2>COLOUR</h2>
                                </ThemeHeading>
                                <div dangerouslySetInnerHTML={{__html: colour}} />
                            </ScrolledIntoView>
                        </div>
                        <div className="_column _column-6 _center _size">
                            <ScrolledIntoView>
                                <ThemeHeading>
                                    <h2>TEXTURE</h2>
                                </ThemeHeading>
                                <div dangerouslySetInnerHTML={{__html: texture}} />
                            </ScrolledIntoView>
                        </div>
                        <div className="_column _column-6 _center _size">
                            <ScrolledIntoView>
                                <ThemeHeading>
                                    <h2>PATTERN</h2>
                                </ThemeHeading>
                                <div dangerouslySetInnerHTML={{__html: pattern}} />
                            </ScrolledIntoView>
                        </div>
                    </>
                )
        }
    }

    renderPageContent(){
        const {title, image, image2, description, gallery} = this.props.product

        const data = [
            {
                img: BASE_PATH + image,
                img2: BASE_PATH + image2,
                content: (<>
                    <ThemeHeading>
                        <h2>{title}</h2>
                    </ThemeHeading>
                    <article dangerouslySetInnerHTML={{ __html: description }}></article>
                </>)
            }
        ]

        // sort by alt
        gallery.sort(function(a, b) {
            if(a.alt && b.alt){
                var nameA = a.alt.toUpperCase(); // ignore upper and lowercase
                var nameB = b.alt.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            }
            // names must be equal
            return 0;
        });

        return (
            <>
                <SplitColumnSection data={data} />
                <section className="_section _section-template-1">
                    <div className="_box">
                        {this.renderContent()}
                    </div>
                </section>
                <GetInTouch />
                <section className="_section _padding-bottom">
                    <div className="_box">
                        <div className="_inner">
                            <MasonaryGridBlog data={gallery} />
                        </div>                        
                    </div>
                </section>
                <GetInTouch />
            </>
        )
    }

    renderSeo(){
        if( this.props.product ){
            const {title, description, image} = this.props.product

            return <Seo 
                title={`${title} | Coral & Hive - Natural Handwoven Rugs`}
                description={description.replace(/(<([^>]+)>)/gi, "")}
                image={BASE_PATH + image}
                keywords={`${title}, rugs, weavers, natural fibres, wooden looms`}
            />
        }
        return <Seo />
    }

    render(){

        return(
            <>
                {this.renderSeo()}
                           
                <Layout transparent={false}>
                    <TemplateOneSection>
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>RUGS</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <p>Our rugs are of the highest quality, handwoven on large wooden looms by talented weavers using natural, ethically sourced fibres. By choosing a Coral & Hive rug you are making a considered decision to buy a unique piece that will breathe natural life into the space it inhabits for you.</p>
                            <p>Start your own rug journey by browsing the different fibres and weaving techniques available, and deciding which suits your vision and lifestyle best.</p>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                    <TabsSection data={this.props.products} />
                    {this.props.product && this.renderPageContent()}
                </Layout>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        products: Object.values(state.publicProductsState),
        product: state.publicProductDetailsState[ownProps.match.params.slug]
    }
}

export default connect(mapStateToProps, {
    fetchPublicProductDetails
})(Rugs)