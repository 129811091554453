const SimpleHeading = props => {
  return (
    <section className="_section _section-simple-heading">
      <div className="_grid">
        <div className="_column">
          <h1 className="_heading">
            {props.heading}
          </h1>
        </div>
      </div>
    </section>
  )
}

export default SimpleHeading