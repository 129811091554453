import React from 'react'
import ThemeHeading from '../ui/ThemeHeading'
import {connect} from 'react-redux'
import {
    newsletterSubscription,
    resetNewsletterSubscription
} from '../../actions'
import history from '../../history'
import NewsletterSubscriptionForm from '../forms/NewsletterSubscriptionForm'
import ScrolledIntoView from '../../ScrolledIntoView'

class NewsletterSubscription extends React.Component{

    componentWillUnmount(){
        this.props.resetNewsletterSubscription()
    }

    componentDidUpdate(){
        if(this.props.subscribeState.errors){
            setTimeout(() => {
                this.props.resetNewsletterSubscription()
            }, 7000)
        }
    }

    onSubmitNewsletterSubscription = formValues => {
        this.props.newsletterSubscription(formValues)
    }

    render(){

        this.props.subscribeState.success && history.push('/newsletter-subscription/thankyou')

        return(
            <section className={`_section _section-template-1 ${this.props.className ? this.props.className : ''}`}>
                <div className="_box-2">
                    <div className="_column">
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>SUBSCRIBE TO OUR NEWSLETTERS</h1>
                                <p>Subscribe to our newsletter for beautiful stories, creative inspiration and rug reveals.</p>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <NewsletterSubscriptionForm 
                                onSubmit={this.onSubmitNewsletterSubscription} 
                                errors={this.props.subscribeState.errors ? this.props.subscribeState.errors : null}
                            />
                        </ScrolledIntoView>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        subscribeState: state.newsletterSubscriptionState
    }
}

export default connect(mapStateToProps, {
    newsletterSubscription,
    resetNewsletterSubscription
})(NewsletterSubscription)