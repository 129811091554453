import React from 'react'
import {NavLink} from 'react-router-dom'
import ScrolledIntoView from '../../ScrolledIntoView'

class TabsSection extends React.Component {

    scrollToPosition = () => {
        setTimeout(() => {
            const element = document.querySelector('#_rugsTabs')
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 120
            
            window.scrollTo({
                top: topPos, // scroll so that the element is at the top of the view
                behavior: 'smooth' // smooth scroll
            })
        },500)    
    }

    renderTabs = () => {
        return this.props.data.map(({title, slug}) => {
            return(
                <NavLink key={slug} to={`/rugs/${slug}`} activeClassName="_active">
                    <div onClick={() => this.scrollToPosition()}>{title}</div>
                </NavLink> 
            )
        })
    }

    render(){

        return(
            <section className="_section" id="_rugsTabs">
                <div className="_fluid">
                    <div className="_column">
                        <ScrolledIntoView>
                            <div className="_tabs">
                                {this.renderTabs()}
                            </div>
                        </ScrolledIntoView>
                    </div>
                </div>
            </section>
        )
    }
}

export default TabsSection