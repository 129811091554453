import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'

import ThemeHeading from '../ui/ThemeHeading'
import SplitColumnSection from '../ui/SplitColumnSection'
import GalleryCarousel from '../ui/GalleryCarousel'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
    fetchPublicGalleryImages,
    fetchPublicTeam
} from '../../actions'
import {BASE_PATH} from '../../paths'

//mp4
import video from '../../assets/videos/C&H-about-us.mp4'

class About extends React.Component{

    componentDidMount(){
        this.props.fetchPublicGalleryImages('weavers')
        this.props.fetchPublicTeam()
    }

    EmbedVideo = () => {
        return (
            <div  className="_iframe-container" dangerouslySetInnerHTML={{ __html: `
              <video title="candhivevideo" width="560" height="315" autoplay loop playsinline controls>
                <source src=${video} type="video/mp4" />
    
              Your browser does not support the video tag.
              </video>
            ` }}></div>
        )
     }

    renderTeamArray = () => {
        const array = this.props.team.map(({image, fullname, position, bios},index) => {
            return {
                img: BASE_PATH + image,
                content: (<>
                    <ThemeHeading>
                        <h2>{fullname}</h2>
                        <h3>{position}</h3>
                    </ThemeHeading>          
                    <div dangerouslySetInnerHTML={{__html: bios}}></div>          
                </>)
            }
        })
        
        return array
    }

    renderGallery = () => {

        let gallery = this.props.data

        // sort by alt
        gallery.sort(function(a, b) {
            if(a.caption && b.caption){
                var nameA = a.caption.toUpperCase(); // ignore upper and lowercase
                var nameB = b.caption.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
            }
            // names must be equal
            return 0;
        });

        return <GalleryCarousel data={gallery} />
    }

    render(){

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="About Us | Coral & Hive - Natural Handwoven Rugs"
                description="Coral & Hive’s home is in Cape Town, South Africa, where we have a creative studio and one of the largest weaving looms in Africa. It’s a humming hive of creativity, where we design and produce perfectly imperfect handmade rugs that go on to live in many inspiring spaces around the world."
            />
            <Layout transparent={false}>

                <TemplateOneSection>
                    <ScrolledIntoView>
                        <ThemeHeading>
                            <h1>ABOUT US</h1>
                        </ThemeHeading>
                    </ScrolledIntoView>
                    <ScrolledIntoView>
                        <p>Coral & Hive’s home is in Cape Town, South Africa, where we have a creative studio and one of the largest weaving looms in Africa. It’s a humming hive of creativity, where we design and produce perfectly imperfect handmade rugs that go on to live in many inspiring spaces around the world. We are supported by our London creative studio and by a family-owned loom partner in India.</p>     
                    </ScrolledIntoView>               
                </TemplateOneSection>

                <section className="_section _section-image-template">
                    <div className="_box">
                        <div className="_column">
                            <ScrolledIntoView>
                                {/* <div className="_iframe-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RH_Et5ufmTk?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div> */}
                                {this.EmbedVideo()}
                            </ScrolledIntoView>
                        </div>
                    </div>
                </section>
                
                <TemplateOneSection>
                    <ScrolledIntoView>
                        <ThemeHeading>
                            <h1 style={{marginBottom: '0'}}>THE TEAM</h1>
                        </ThemeHeading>
                    </ScrolledIntoView>
                </TemplateOneSection>

                {/* <SplitColumnSection data={team} even /> */}
                <SplitColumnSection data={this.renderTeamArray()} even />

                <TemplateOneSection>
                    <ScrolledIntoView>
                        <ThemeHeading>
                            <h1>OUR WEAVERS</h1>
                        </ThemeHeading>
                    </ScrolledIntoView>
                    
                    <ScrolledIntoView>
                        <p><strong><span className="_gold">South Africa</span></strong><br />South Africa We employ some of the most talented weavers in South Africa, and they are at the very heart of Coral & Hive. They are women that deeply appreciate the art of weaving, not only for the products they produce, but for the sense of connection, joy, hope, purpose and peace it brings. They sing stories into the fibres as they keep a beautiful craft alive with their creations.</p>
                    </ScrolledIntoView>
                    <ScrolledIntoView>
                        <p><strong><span className="_gold">India</span></strong><br />We work with family-owned looms and weavers in India to ensure that we can offer Coral & Hive clients creative freedom with the best natural Indian fibres, colours and weaving techniques. Everything from laid back flat weaves to luxurious silk and wool blends are made by these exceptional craftspeople.</p>
                    </ScrolledIntoView>
                </TemplateOneSection>

                <section className="_section _gallery-carousel-section">
                    <ScrolledIntoView>
                        {this.props.data && this.renderGallery()}
                    </ScrolledIntoView>
                </section>

                {/* <SplitColumnSection data={data} odds /> */}

            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.publicGalleryImagesState['weavers'],
        team: Object.values(state.publicTeamState)
    }
}

export default connect(mapStateToProps, {
    fetchPublicGalleryImages,
    fetchPublicTeam
})(About)