import _ from 'lodash'
import {
    FETCH_SHOPS,
    CREATE_SHOP,
    FETCH_SHOP,
    UPDATE_SHOP,
    DELETE_SHOP,
    RESTORE_SHOP
} from '../../actions/types'

const initialState = {}

const shopsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_SHOPS:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_SHOP:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_SHOP:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_SHOP:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_SHOP:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_SHOP:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default shopsReducer