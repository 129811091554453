import React, { 
  useEffect, 
  useState 
} from "react";
import {
  useStripe
} from "@stripe/react-stripe-js";
import {connect} from 'react-redux'
import {
  paymentSucceeded
} from '../../actions/checkout'

const StripePaymentStatus = props => {
  const stripe = useStripe();

  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          props.paymentSucceeded(props.checkoutState.order_id)
          setMessage("Approved. Please wait while we are redirecting you to your order confirmation")
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  },);

  return (
    <p style={{marginTop:'0'}}>{message ? message : 'Please wait while we are processing your transaction'}</p>
  )
}

const mapStateToProps = state => {
  return {
    checkoutState: state.checkoutState,
    paymentStatus: state.paymentStatusState
  }
}

export default connect(mapStateToProps, {
  paymentSucceeded,
})(StripePaymentStatus)