import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import CheckoutForm from './CheckoutForm'
import {connect} from 'react-redux'
import {
  fetchCustomerAcountDetails
} from '../../actions/customer'
import {
  resetForms
} from '../../actions'
import {
  checkout
} from '../../actions/checkout'

class CheckoutPage extends React.Component{

    componentDidMount(){
        this.props.fetchCustomerAcountDetails(this.props.auth.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = data => {
      this.props.checkout(
          this.props.auth.token ? this.props.auth.token : null, 
          data
      )
    }

    render(){

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="Checkout | Coral & Hive - Natural Handwoven Rugs"
                description=""
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                  <Link to="/">Home</Link><Divider /><Link to="/shop">Shop</Link><Divider /><Link to="/cart">Cart</Link><Divider />Checkout
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <SimpleHeading heading="Checkout" />
              </ScrolledIntoView>
              <ScrolledIntoView>
                <CheckoutForm 
                  onSubmit={this.onSubmit} 
                  initialValues={this.props.profile ? this.props.profile.details : null}  
                  errors={this.props.checkoutState.errors ? this.props.checkoutState.errors : null}
                  auth={this.props.auth}
                  cart={this.props.cartState}
                  orderId={this.props.checkoutState.order_id ? this.props.checkoutState.order_id : null}
                />
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
  return {
    auth: state.customerAuthState,
    profile: state.customerProfileState,
    cartState: state.cartState,
    checkoutState: state.checkoutState
  }
}

export default connect(mapStateToProps, {
  fetchCustomerAcountDetails,
  resetForms,
  checkout
})(CheckoutPage)