import React from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {
    hideNewsletterPopup,
    newsletterSubscription,
    resetNewsletterSubscription
} from '../../actions'
import history from '../../history'
import NewsletterPopupForm from '../forms/NewsletterPopupForm'
import CloseIcon from '../ui/CloseIcon'

class NewsletterPopup  extends React.Component {

    componentWillUnmount(){
        this.props.resetNewsletterSubscription()
        this.props.hideNewsletterPopup()
    }

    componentDidUpdate(){
        if(this.props.subscribeState.errors){
            setTimeout(() => {
                this.props.resetNewsletterSubscription()
            }, 7000)
        }
    }

    onClick = () => { 
        this.props.hideNewsletterPopup()
    }

    onSubmitNewsletterSubscription = formValues => {
        this.props.newsletterSubscription(formValues)
    }

    render() {

        this.props.subscribeState.success && history.push('/newsletter-subscription/thankyou')

        return (
            <>
                {
                    !this.props.newsletterPopupState && (
                        <div className='_popup-newsletter'>
                          <div className='_inner-panel'>
                            <div className="_theme_heading">
                                <h1>SUBSCRIBE TO OUR NEWSLETTERS</h1>
                                <p>Subscribe to our newsletter for beautiful stories, creative inspiration and rug reveals.</p>
                                <NewsletterPopupForm 
                                  onSubmit={this.onSubmitNewsletterSubscription} 
                                  errors={this.props.subscribeState.errors ? this.props.subscribeState.errors : null}
                                />
                                {/*<button onClick={this.onClick} className="_button-close">
                                Maybe Later
                                </button>*/}
                                <div className="_button-close">
                                  <CloseIcon onClick={this.onClick} />
                                </div>
                            </div>
                          </div>
                        </div>
                    ) 
                    
                }                
            </>          
        )      
    }
}

const mapStateToProps = state => {
  return {
    newsletterPopupState: state.newsletterPopupState.accept,
    subscribeState: state.newsletterSubscriptionState
  }
}

export default connect(mapStateToProps, {
  hideNewsletterPopup,
  newsletterSubscription,
  resetNewsletterSubscription
})(NewsletterPopup)