import _ from 'lodash'
import {
  FETCH_PUBLIC_SHOP_CATEGORIES,
  FETCH_PUBLIC_SHOP_CATEGORY
} from '../actions/types'

const initialState = {}

const publicShopCategoriesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_SHOP_CATEGORIES:
            return {
                ...state, ..._.mapKeys(action.payload, 'slug')
            }
        
        case FETCH_PUBLIC_SHOP_CATEGORY:
            return {
              ...state, [action.payload.slug]: action.payload
            }
        default:
            return state
    }
}

export default publicShopCategoriesReducer