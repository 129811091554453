import React from 'react'
import Header from './Header'
import HeaderTransparent from './HeaderTransparent'
import Nav from './Nav'
import Footer from './Footer'
import Cookie from '../ui/Cookie'
import PopupMessage from '../ui/PopupMessage'
import PopupNewsletter from '../ui/PopupNewsletter'
import {
    clearCartIfExpired
  } from '../../utils'
import {
    hidePopup,
  } from '../../actions'
import {connect} from 'react-redux'

class Layout extends React.Component {

    state = {
        headerTransform: false,
        menuOpen: false
    }

    componentDidMount(){
        clearCartIfExpired()
        window.addEventListener('scroll', () => {
          this.handleTransform()
        }, { capture: true })
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', () => {
          this.handleTransform()
        }, { capture: true })
        this.props.hidePopup()
    }

    onToggleMenu = () => {        
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    handleTransform = () => {

        let y = window.scrollY
        
        if(y > 100){
            if(!this.state.headerTransform){
                this.setState({
                    headerTransform: !this.state.headerTransform
                })
            }
        } else {
            if(this.state.headerTransform){
                this.setState({
                    headerTransform: !this.state.headerTransform
                })
            }
        }
    }

    renderHeader = () => {
        if (this.props.transparent){
            return <HeaderTransparent headerTransform={this.state.headerTransform} onClick={this.onToggleMenu} />
        } else {
            return <Header headerTransform={this.state.headerTransform} className="_show" onClick={this.onToggleMenu} />
        }
    }

    render(){

        const {transparent} = this.props
        return(
            <div className={`_wrapper ${transparent ? '_transparent' : ''} ${this.state.menuOpen ? '_menu-open' : '' }`}>
                {this.renderHeader()}
                <Nav hamburgerIcon={false} onClick={this.onToggleMenu} />
                <div className={`_page-content ${transparent ? '' : '_padding-top'}`}>
                    {this.props.children}
                </div>
                <Footer />
                <Cookie />
                <PopupMessage />
                <PopupNewsletter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      popupState: state.popupState,
      newsletterPopupState: state.newsletterPopupState,
    }
  }
  
  export default connect(mapStateToProps, {
    hidePopup,
  })(Layout)