import React from 'react'
import ScrolledIntoView from '../../ScrolledIntoView'

class TemplateImageSection extends React.Component{

    render(){

        const {img, img2, alt} = this.props

        return (
            <section className={`_section _section-image-template _center ${this.props.className ? this.props.className : '' }`}>
                <div className="_box">
                    <div className="_column">
                        <ScrolledIntoView>
                            <figure 
                                style={{
                                    backgroundImage: img2 ? `url(${img2})` : 'none'
                                }}
                                className={`${img2 ? '_animation-image-swith' : ''}`}
                            >
                                <img src={img} alt={alt ? alt : ""} />               
                            </figure>
                        </ScrolledIntoView>
                        {/* <img src={this.props.img} alt={this.props.alt ? this.props.alt : ''} /> */}
                    </div>
                </div>
            </section>
        )
    }
}

export default TemplateImageSection