import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import SignInForm from '../forms/SignInForm'
import {
    signIn
} from '../../actions'
import {connect} from 'react-redux'
import history from '../../history'
import ScrollToTop from '../../ScrollToTop'
import Helmet from 'react-helmet'

class SignIn extends React.Component{

    onSubmit = formValues => {
        this.props.signIn(formValues)
    }

    render(){

        if(this.props.auth.token){
            history.push('dashboard')
        }

        return(
            <>
            <ScrollToTop />
            <Helmet>
                <title>Admin Log In | Coral & Hive - Natural Handwoven Rugs</title>
            </Helmet>
            <Layout transparent={false}>
                <TemplateOneSection className="_padding-bottom-none">
                    <ThemeHeading>
                        <h1>Sign In</h1>
                    </ThemeHeading>
                    <p>Sign in to your Admin Dashboard.</p>
                </TemplateOneSection>
                <section className="_section _section-form _padding-bottom">
                    <div className="_box-2">
                        <div className="_column _column-8">
                            <SignInForm 
                                onSubmit={this.onSubmit} 
                                errors={this.props.auth.errors ? this.props.auth.errors : null} 
                            />
                        </div>
                    </div>
                </section>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.authState
    }
}

export default connect(mapStateToProps,{
    signIn
})(SignIn)