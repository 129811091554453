import React from 'react'
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//as seen in
import elle from '../../assets/img/partners/elle-decoration-logo@2x.jpg'
import garden from '../../assets/img/partners/house-and-garden-logo@2x.jpg'
import leisure from '../../assets/img/partners/handl-logo@2x.png'
import visi from '../../assets/img/partners/visi-logo@2x.jpg'
import enki from '../../assets/img/partners/enki-logo@2x.jpg'
import wallpaper from '../../assets/img/partners/wallpaper.jpg'
import veranda from '../../assets/img/partners/veranda.jpg'
import local from '../../assets/img/partners/the-local-edit.jpg'
import thelist from '../../assets/img/partners/the-list-logo@2x.jpg'
import britishVogue from '../../assets/img/partners/british-vogue@2x.jpg'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`_gallery-next-arrow ${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`_gallery-prev-arrow ${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
}

class PartnersCarousel extends React.Component {

    render(){

        const settings = {
            centerMode: true,
            variableWidth: true,
            infinite: true,
            slidesToShow: 3,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5000,
            easing: 'ease-in-out',
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };

        return(
            <div className='_partners-carousel-container'>
                <Slider {...settings}>
                    <a href="https://www.elledecoration.co.uk/houses/a36429234/studio-duggan-london-home/" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={elle} alt="Elle Decoration" />
                    </a>
                    <a href="https://www.houseandgarden.co.uk/article/the-list-designer-insight-coral-hive" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={garden} alt="House & Garden" />
                    </a>
                    <a href="https://houseandleisure.co.za/volume-3-breathe/" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={leisure} alt="House & Leisure" />
                    </a>
                    <a href="/" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={thelist} alt="The List" />
                    </a>
                    <a href="https://visi.co.za/" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={visi} alt="Visi" />
                    </a>
                    <a href="https://enkimagazine.com" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={enki} alt="Enki" />
                    </a>
                    <a href="https://www.wallpaper.com/design/rich-mnisi-furniture-collection-southern-guild" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={wallpaper} alt="Wallpaper" />
                    </a>
                    <a href="https://www.veranda.com/" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={veranda} alt="Veranda Magazine" />
                    </a>
                    <a href="https://www.thelocaledit.com/podcast-blog/blog-post-title-four-7x5n6-d6cw4" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={local} alt="The Local Edit" />
                    </a>
                    <a href="https://www.vogue.co.uk/arts-and-lifestyle/article/best-rugs" target="_blank" rel="noreferrer" className="_partners-item">
                        <img src={britishVogue} alt="British Vogue" />
                    </a>
                    
                </ Slider>
            </div>
        )
    }
}

export default PartnersCarousel