import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import {connect} from 'react-redux'
// import history from '../../history'
import {BASE_PATH} from '../../paths'
import {
  customerSignOut
} from '../../actions/customer'

class OrderConfirmationPage extends React.Component{

    signout = e => {
        e.preventDefault()
        this.props.customerSignOut(this.props.auth.token)
    }

    renderCartItems = () => {
      return this.props.order.items.map(({product_name, product_image, id, product_price, quantity,total}) => {
        return (
          <tr key={id}>
            <td>
              <img className='_item-image' src={BASE_PATH+product_image} alt="" />
            </td>
            <td className='_product-name'>
              <p>{product_name}</p>
            </td>
            <td className='_price'>
              <p>£ {product_price}</p>
            </td>
            <td className='_quantity'>
              {quantity}
            </td>
            <td className='_subtotal'>
              <p> £ {total}</p>
            </td>
          </tr>
        )
      })
    }

    render(){

        if(!this.props.order.items)
        return <></>

        const {billing_name, billing_surname, billing_address, billing_city, billing_region, billing_country, billing_postal_code, subtotal, discount, freight, total, billing_phone, billing_email, shipping_name, shipping_surname, shipping_country, shipping_region, shipping_city, shipping_street_address, shipping_postal_code} = this.props.order

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="Order Complete | Coral & Hive - Natural Handwoven Rugs"
                description=""
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                  <Link to="/">Home</Link><Divider /><Link to="/shop">Shop</Link><Divider /><Link to="/cart">Cart</Link><Divider /><Link to="/checkout">Checkout</Link><Divider />Order Summary
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <SimpleHeading heading="Order Complete" />
              </ScrolledIntoView>
              <ScrolledIntoView>
                <section className='_section _section-payment'>
                  <div className='_grid'>
                    <div className='_column'>
                        <table className='_cart-items-table'>
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th></th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderCartItems()}
                          </tbody>
                        </table>
                        <div className='_horline' style={{maxWidth: '800px', margin: '0 auto'}} />
                        <div className='_flex-panel'>
                          <div></div>
                          <table className='_order-details' >
                            <tbody>
                              <tr>
                                <td>Name</td>
                                <td>{billing_name} {billing_surname}</td>
                              </tr>
                              <tr>
                                <td>Mobile</td>
                                <td>{billing_phone}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>{billing_email}</td>
                              </tr>
                              <tr>
                                <td valign="top">Billing Address</td>
                                <td>{billing_address},<br />{billing_city},<br />{billing_region},<br />{billing_country},<br />{billing_postal_code}</td>
                              </tr>
                              <tr>
                                <td valign="top">Shipping Address</td>
                                <td>{shipping_name} {shipping_surname},<br />{shipping_street_address},<br />{shipping_city},<br />{shipping_region},<br />{shipping_country},<br />{shipping_postal_code}</td>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>£ {subtotal}</td>
                              </tr>
                              <tr>
                                <td>Discount</td>
                                <td>£ {discount}</td>
                              </tr>
                              <tr>
                                <td>Delivery</td>
                                <td>£ {freight}</td>
                              </tr>
                              <tr>
                                <td>Total</td>
                                <td>£ {total}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className='_horline' style={{maxWidth: '800px', margin: '0 auto'}} />
                        <div className='_flex-panel'>
                          <div></div>
                          <div className='_buttons'>
                            <Link to="/shop" className='_home-button'>Continue Shopping</Link>
                            {this.props.auth.token && <button className='_button' onClick={this.signout}>Logout</button>}
                          </div>
                        </div>
                    </div>
                  </div>
                </section>
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
  return {
    auth: state.customerAuthState,
    order: state.paymentStatusState
  }
}

export default connect(mapStateToProps, {
  customerSignOut
})(OrderConfirmationPage)