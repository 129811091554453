import React from 'react'
import SocialIcons from '../ui/SocialIcons'
import Logo from '../ui/Logo'
import SmallLogo from '../ui/SmallLogo'
import HamburgerIcon from '../ui/HamburgerIcon'
import CloseIcon from '../ui/CloseIcon'
import CartProfileIcons from '../ui/CartProfileIcons'

class HeaderContent extends React.Component {

    renderIcon = () => {
        if(this.props.hamburgerIcon){
            return <HamburgerIcon onClick={this.props.onClick} />
        } else {
            return <CloseIcon onClick={this.props.onClick} />
        }
    }

    renderLogo = () => {
        if(this.props.smallLogo){
            return <SmallLogo fill={this.props.fill} />
        } else {
            return <Logo fill={this.props.fill} />
        }
    }

    render(){
        return(
            <div className="_inner">
                <div className="_item">
                    <SocialIcons />
                </div>
                <div className="_item">
                    {this.renderLogo()}
                </div>
                <div className="_item">
                    <div className='_left-actions-flexbox'>
                        <CartProfileIcons />
                        {this.renderIcon()}
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderContent