import _ from 'lodash'
import {
    FETCH_TEAM,
    CREATE_TEAM_MEMBER,
    FETCH_TEAM_MEMBER,
    UPDATE_TEAM_MEMBER,
    DELETE_TEAM_MEMBER,
    RESTORE_TEAM_MEMBER
} from '../actions/types'

const initialState = {}

const teamReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_TEAM:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_TEAM_MEMBER:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_TEAM_MEMBER:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_TEAM_MEMBER:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_TEAM_MEMBER:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_TEAM_MEMBER:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default teamReducer