import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import SplitColumnSection from '../ui/SplitColumnSection'
import {Link} from 'react-router-dom'
import CardsSection from '../ui/CardsSection'
import {connect} from 'react-redux'
import {
    fetchPublicPosts
} from '../../actions'
import {BASE_PATH} from '../../paths'
import ScrolledIntoView from '../../ScrolledIntoView'
import NewsletterSubscription from '../ui/NewsletterSubscription'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'

class Blog extends React.Component{

    componentDidMount(){
        this.props.fetchPublicPosts()
    }

    renderPageContent(){
        const {image, headline, summary, slug} = this.props.data[0]

        const data = [
            {
                img: BASE_PATH + image,
                alt: headline,
                content: (<>
                    <ThemeHeading>
                        <h1>{headline}</h1>
                    </ThemeHeading>
                    <p>{summary}</p>

                    <Link to={`/blog/${slug}`} className="_button">Read More</Link>
                </>)
            }
        ]

        const cards = this.props.data.slice(1,this.props.data.length)

        return (
            <>
                <SplitColumnSection data={data} />
                <CardsSection data={cards} />
                <NewsletterSubscription />
            </>
        )
    }

    render(){

        return(
            <>
                <ScrollToTop />
                <Seo 
                    title="Blog | Coral & Hive - Natural Handwoven Rugs"
                    description="We love to share an inside look at our beautiful world of rug design and small, creative business management with you. We hope you slow down, relax and enjoy these inspiring stories of creativity from our humming hive."
                    image={this.props.data[0] ? BASE_PATH + this.props.data[0].image : null}
                />
                <Layout transparent={false}>
                    <TemplateOneSection>
                        <ScrolledIntoView>
                        <ThemeHeading>
                            <h1>BLOG</h1>
                        </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <p>We love to share an inside look at our beautiful world of rug design and small, creative business management with you. We hope you slow down, relax and enjoy these inspiring stories of creativity from our humming hive.</p>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                    {this.props.data[0] && this.renderPageContent()}
                </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: Object.values(state.publicPostsState),
    }
}

export default connect(mapStateToProps, {
    fetchPublicPosts
})(Blog)