import React from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {
  hidePopup
} from '../../actions'

class PopupMessage extends React.Component {

    render() {

        const {message, children, buttonText} = this.props.popupState

        return(
            ReactDOM.createPortal(
                <div className={`_popup-message ${message ? '' : '_hide'}`}>
                  <div className='_inner-panel'>
                    <p style={{marginTop: '0'}}>{message && message}</p>
                    <div className='_buttons'>
                      <button className='_button' onClick={this.props.hidePopup}>
                        {buttonText ? buttonText : 'OK'}
                      </button>
                      {children}
                    </div>
                  </div>
                </div>,
                document.querySelector("#popup-message")
            )            
        )      
    }
}

const mapStateToProps = state => {
  return {
    popupState: state.popupState
  }
}

export default connect(mapStateToProps, {
  hidePopup
})(PopupMessage)