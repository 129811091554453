import _ from 'lodash'
import {
  CUSTOMER_ORDERS,
  CUSTOMER_ORDER,
  CUSTOMER_SIGNOUT
} from '../actions/types'

const initialState = {}

const customerOrdersReducer = (state = initialState, action) => {
    switch(action.type){
      
        case CUSTOMER_ORDERS:
            return _.mapKeys(action.payload, 'id')

        case CUSTOMER_ORDER:
            return {
                ...state, [action.payload.id]: action.payload
            }

        case CUSTOMER_SIGNOUT:
          return initialState

        default:
            return state
    }
}

export default customerOrdersReducer