import {Link} from 'react-router-dom'
import icon from '../../assets/img/profile.svg'

const ProfileIcon = () => {
  return (
    <Link to="/my-account" className='_profile-icon'>
      <img src={icon} alt="Profile" />
    </Link>
  )
}

export default ProfileIcon