import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import DashboardNav from './DashboardNav'
import {readableDate, formatPrice} from '../../utils'
import {fetchCustomerOrders} from '../../actions/customer'
import {connect} from 'react-redux'

class AccountOrders extends React.Component{

  componentDidMount(){
    this.props.fetchCustomerOrders(this.props.auth.token)
  }

  renderOrderItems(){
    let {orders} = this.props

    orders.sort(function(a, b){
      if(a.id > b.id) { return -1; }
      if(a.id < b.id) { return 1; }
      return 0;
    })

    return orders.map(({id,updated_at,status,total}) => {
      return (
        <tr>
          <td>#{id}</td>
          <td>{readableDate(updated_at)}</td>
          <td>{status}</td>          
          <td>£ {formatPrice(total)}</td>
          <td>
            <Link to={`/my-account/orders/${id}`} className="_button">View Order</Link>
          </td>
        </tr>
      )
    })
  }

  renderOrdersTable(){
    if(this.props.orders.length > 0)
    return (
      <table className='_customer-orders-table'>
        <thead>
          <tr>
            <th>Order</th>
            <th>Date</th>
            <th>Status</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.renderOrderItems()}
        </tbody>
      </table>
    )

    return <>You don't have any orders</>
    
  }

    render(){

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="My Orders | My Account | Coral & Hive - Natural Handwoven Rugs"
                description=""
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                  <Link to="/shop">Shop</Link><Divider /><Link to="/my-account/dashboard">My Account</Link><Divider />My Orders
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <SimpleHeading heading="My Account" />
              </ScrolledIntoView>
              <ScrolledIntoView>
                <section className='_section _section-account'>
                  <div className='_grid'>
                    <div className='_column'>
                      <div className='_flex-panel'>
                        <DashboardNav />
                        <div className='_account-dashboard-display'>
                          <h3>My Orders</h3>
                          {this.renderOrdersTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
  return {
    auth: state.customerAuthState,
    orders: Object.values(state.customerOrdersState)
  }
}

export default connect(mapStateToProps, {
  fetchCustomerOrders
})(AccountOrders)