import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import DashboardNav from './DashboardNav'
import AccountUpdateForm from './AccountUpdateForm'
import {
  fetchCustomerAcountDetails,
  updateCustomerAccountDetails
} from '../../actions/customer'
import {
  resetForms
} from '../../actions'
import {connect} from 'react-redux'

class AccountProfile extends React.Component{

    componentDidMount(){
      this.props.fetchCustomerAcountDetails(this.props.auth.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
      this.props.updateCustomerAccountDetails(this.props.auth.token, formValues)
    }

    render(){
      
        return(
            <>
            <ScrollToTop />
            <Seo 
                title="Profile | My Account | Coral & Hive - Natural Handwoven Rugs"
                description=""
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                  <Link to="/shop">Shop</Link><Divider /><Link to="/my-account/dashboard">My Account</Link><Divider />Profile
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <SimpleHeading heading="My Account" />
              </ScrolledIntoView>
              <ScrolledIntoView>
                <section className='_section _section-account'>
                  <div className='_grid'>
                    <div className='_column'>
                      <div className='_flex-panel'>
                        <DashboardNav />
                        <div className='_account-dashboard-display'>
                          <h3>Profile</h3>
                          <AccountUpdateForm 
                            onSubmit={this.onSubmit} 
                            initialValues={this.props.profile ? this.props.profile.details : null}  
                            errors={this.props.profile ? this.props.profile.errors : null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
  return {
    auth: state.customerAuthState,
    profile: state.customerProfileState
  }
}

export default connect(mapStateToProps, {
  fetchCustomerAcountDetails,
  updateCustomerAccountDetails,
  resetForms
})(AccountProfile)