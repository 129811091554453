import React from 'react'
import Layout from '../layout/Layout'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import Breadcrums from '../ui/Breadcrums'
import SimpleHeading from '../ui/SimpleHeading'
import Form from '../forms/PasswordForgotForm'
import {forgotPassword} from '../../actions/password-forgot'
import {connect} from 'react-redux'
import ScrolledIntoView from '../../ScrolledIntoView'

class ForgotPassword extends React.Component {

  onSubmit = formValues => {
    this.props.forgotPassword(formValues)
  }

  render(){

    return (
      <>
        <Seo
          title="Forgot Password | Coral & Hive - Natural Handwoven Rugs"
          description={null}
          image={null}
        ><meta name="robots" content="noindex, nofollow" />
        </Seo>
        <ScrollToTop />
        <Layout transparent={false}>
          <ScrolledIntoView>
            <Breadcrums>
              <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
              Forgot Password
            </Breadcrums>
            <SimpleHeading heading="Forgot Password" />
            <section className='_section _section-not-found'>
              <div className='_grid'>
                <div className='_column'>   
                  <div className='_form-panel'>
                    <h1 className='_page-heading'>Send Password Reset Link</h1>
                    <Form
                        onSubmit={this.onSubmit} 
                        errors={this.props.send.errors ? this.props.send.errors : null}
                    />
                  </div>
                </div>    
              </div>    
            </section>
          </ScrolledIntoView>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return{
    send: state.forgotPasswordState
  }
}

export default connect(mapStateToProps, {
  forgotPassword
})(ForgotPassword)