import React from 'react'
import Layout from '../layout/Layout'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import Breadcrums from '../ui/Breadcrums'
import SimpleHeading from '../ui/SimpleHeading'
import Form from '../forms/PasswordForgotResetForm'
import {forgotPasswordReset} from '../../actions/password-forgot'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import ScrolledIntoView from '../../ScrolledIntoView'

class ForgotPasswordReset extends React.Component {

  onSubmit = formValues => {
    this.props.forgotPasswordReset(formValues)
  }

  render(){

    if(!this.props.code)
    return <Redirect to="/forgot-password" />

    return (
      <>
        <Seo
          title="Forgot Password Reset | Coral & Hive - Natural Handwoven Rugs"
          description={null}
          image={null}
        ><meta name="robots" content="noindex, nofollow" />
        </Seo>
        <ScrollToTop />
        <Layout>
          <ScrolledIntoView>
            <Breadcrums>
              <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
              Forgot Password&nbsp;/&nbsp;Reset
            </Breadcrums>
            <SimpleHeading heading="Forgot Password" />
            <section className='_section _section-not-found'>
              <div className='_grid'>
                <div className='_column'>   
                  <div className='_form-panel'>
                    <h1 className='_page-heading'>Reset Password</h1>
                    <Form
                        onSubmit={this.onSubmit} 
                        errors={this.props.send.errors ? this.props.send.errors : null}
                        code={this.props.code}
                    />
                  </div>
                </div>    
              </div>    
            </section>
          </ScrolledIntoView>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return{
    send: state.forgotPasswordResetState,
    code: state.forgotPasswordOtpState.success ? state.forgotPasswordOtpState.success.code : null
  }
}

export default connect(mapStateToProps, {
  forgotPasswordReset
})(ForgotPasswordReset)