import React from 'react'

const SocialIcons = ({fill}) => {
    return(
        <ul className="_social-icons">
            <li>
                <a href="http://www.facebook.com/coralandhive" target="_blank" rel="noreferrer">
                    <svg id="facebook_icon" data-name="facebook icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <g id="Group_51" data-name="Group 51">
                            <path id="Path_25" data-name="Path 25" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm3.73,15.528H16.29v8.7H12.674v-8.7H10.955V12.454h1.719V10.465a3.391,3.391,0,0,1,3.649-3.649l2.68.01V9.81H17.058a.736.736,0,0,0-.767.837v1.807h2.755Z" fill={fill ? fill : "#c1b37f"} />
                        </g>
                    </svg>
                </a>
            </li>
            <li>
                <a href="http://instagram.com/coralandhive" target="_blank" rel="noreferrer">
                    <svg id="instagram_icon" data-name="instagram icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <path id="Path_101" data-name="Path 101" d="M212.742,209.871A2.871,2.871,0,1,1,209.871,207,2.871,2.871,0,0,1,212.742,209.871Zm0,0" transform="translate(-194.871 -194.871)" fill={fill ? fill : "#c1b37f"}/>
                        <path id="Path_102" data-name="Path 102" d="M149.26,137.47a2.852,2.852,0,0,0-1.635-1.635,4.764,4.764,0,0,0-1.6-.3c-.908-.041-1.181-.05-3.481-.05s-2.572.009-3.481.05a4.768,4.768,0,0,0-1.6.3,2.854,2.854,0,0,0-1.635,1.635,4.768,4.768,0,0,0-.3,1.6c-.041.908-.05,1.181-.05,3.481s.009,2.572.05,3.481a4.766,4.766,0,0,0,.3,1.6,2.852,2.852,0,0,0,1.635,1.635,4.76,4.76,0,0,0,1.6.3c.908.041,1.18.05,3.48.05s2.573-.009,3.481-.05a4.759,4.759,0,0,0,1.6-.3,2.852,2.852,0,0,0,1.635-1.635,4.771,4.771,0,0,0,.3-1.6c.041-.908.05-1.181.05-3.481s-.009-2.572-.05-3.481A4.761,4.761,0,0,0,149.26,137.47Zm-6.714,9.5a4.423,4.423,0,1,1,4.423-4.423A4.423,4.423,0,0,1,142.546,146.972Zm4.6-7.987a1.034,1.034,0,1,1,1.034-1.034A1.034,1.034,0,0,1,147.144,138.985Zm0,0" transform="translate(-127.546 -127.55)" fill={fill ? fill : "#c1b37f"}/>
                        <path id="Path_103" data-name="Path 103" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm8.561,18.551a6.319,6.319,0,0,1-.4,2.091,4.4,4.4,0,0,1-2.519,2.519,6.324,6.324,0,0,1-2.091.4c-.919.042-1.212.052-3.551.052s-2.633-.01-3.551-.052a6.324,6.324,0,0,1-2.091-.4,4.4,4.4,0,0,1-2.519-2.519,6.318,6.318,0,0,1-.4-2.091C6.4,17.633,6.387,17.339,6.387,15s.01-2.633.052-3.551a6.32,6.32,0,0,1,.4-2.091A4.407,4.407,0,0,1,9.358,6.839a6.325,6.325,0,0,1,2.091-.4c.919-.042,1.212-.052,3.551-.052s2.633.01,3.551.052a6.327,6.327,0,0,1,2.091.4,4.405,4.405,0,0,1,2.519,2.519,6.319,6.319,0,0,1,.4,2.091c.042.919.052,1.212.052,3.551S23.6,17.633,23.561,18.551Zm0,0" fill={fill ? fill : "#c1b37f"}/>
                    </svg>

                </a>
            </li>
            <li>
                <a href="https://www.pinterest.com/coralandhive/" target="_blank" rel="noreferrer">
                    <svg id="pinterest_icon" data-name="pinterest icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <g id="Group_204" data-name="Group 204" transform="translate(0)">
                            <g id="Group_203" data-name="Group 203">
                            <path id="Path_104" data-name="Path 104" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm1.17,19.056A4.879,4.879,0,0,1,13.755,17.9c-.472,2.479-1.05,4.856-2.76,6.1-.529-3.744.774-6.557,1.38-9.544-1.031-1.738.124-5.231,2.3-4.371,2.676,1.059-2.319,6.456,1.035,7.131,3.5.7,4.931-6.077,2.76-8.282-3.139-3.184-9.131-.073-8.394,4.485.18,1.114,1.329,1.451.459,2.991C8.526,15.968,7.926,14.381,8,12.27a6.757,6.757,0,0,1,6.1-6.21C17.88,5.636,21.428,7.447,21.917,11,22.47,15.019,20.211,19.369,16.17,19.056Z" transform="translate(0)" fill={fill ? fill : "#c1b37f"}/>
                            </g>
                        </g>
                    </svg>

                </a>
            </li>
            
        </ul>
    )
}

export default SocialIcons