import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input
} from '../forms/fields'

import Recaptcha from 'react-recaptcha'

class AccountRegisterForm extends React.Component {

  state= {
    capchaLoaded: null,
    capchaVerified: null
  }

    renderErrors = () => {

        if(!this.props.errors){
            return <></>
        }

        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }
        
        return Object.values(this.props.errors).map((item, index) => {   
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
      console.log('recapcha has loaded')
      this.setState({ capchaLoaded: true })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({ capchaVerified: true })
    }

    onSubmit = formValues => {
      // if(!this.state.capchaLoaded)
      // return

      // if(!this.state.capchaVerified)
      // console.log('Robot filter denied')

      // else 
      this.props.onSubmit(formValues)
    }

    render(){
        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                
                <div className="_form-row">
                    <Field name="name" type="text" component={Input} label="Name" />
                    <Field name="surname" type="text" component={Input} label="Surname" />
                </div>
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Enter Email Address" />
                </div>
                <div className="_form-row">
                    <Field name="password" type="password" component={Input} label="Enter Password" />
                </div>
                <div className="_form-row">
                    <Field name="password_confirmation" type="password" component={Input} label="Confirm Password" />
                </div>
                <br />
                {/* <div className="_form-row">
                    <Field name="accept_terms" component={Checkbox2} label={(<AcceptTermsLink />)} />
                </div>
                <div className="_form-row">
                    <Field name="subscribe" component={Checkbox2} label="Subscribe to our BLOG for exclusive news and articles and enjoy a 5% DISCOUNT VOUCHER when purchasing in-store." />
                </div> */}
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6Lf25pAfAAAAAGjQv-ug0i8FQTMH7451quTi_sV7"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                </div>
                <br />
                 
                <div className="_form-group">
                    <button className={`_button _button-2 _button-submit customer-registration-submitted ${this.state.button === 'Sending' ? '_sending' : ''}`}>Register</button>
                </div>
                {this.props.errors && this.renderErrors()}
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.name){
      errors.name = "You must enter your name"
    }

    if(!formValues.surname){
      errors.surname = "You must enter your surname"
    }

    if(!formValues.email){
        errors.email = "You must enter your username / email"
    }

    if(!formValues.password){
        errors.password = "You must enter your password"
    }

    if(!formValues.password_confirmation){
      errors.password_confirmation = "You must confirm your password"
    }

    // if(!formValues.accept_terms){
    //   errors.accept_terms = "You must accept our Terms & Conditions"
    // }

    return errors
}

export default reduxForm({
    form: 'accountregister',
    validate
})(AccountRegisterForm)