import React from 'react'
import SimpleCarousel from './SimpleCarousel'
import TemplateFourSection from '../ui/TemplateFourSection'
import ScrolledIntoView from '../../ScrolledIntoView'

class Testimonials extends React.Component {

    renderItems = data => {
        return data.map(({msg, person}, index) => {
            return (
                <div key={index}>
                    <div className="_item">
                        {msg}
                        <h3 style={{textTransform: 'uppercase'}}>{person}</h3>
                    </div>
                </div>
            )
        })
    }

    render(){

        const testimonials = [
            {
                msg: (
                    <p><i>“I love working with Coral and Hive because you not only offer a beautiful product, but as a company you care deeply about your team and your supply chain. I know when I specify one of your rugs, my client will not only receive a rug that they will cherish for decades, but within each fiber will be woven a feeling of love and care. When I specify your pieces, I know I am supporting a supply chain that is adding positive value to the world.”</i></p>
                ),
                person: "Wren of Be-Kin Design"
            },
            {
                msg: (
                    <p><i>“When I came across Coral and Hive, it felt like finding a needle in a haystack. The quality of their rugs combined with their mission is what drew me in. I love their support of weavers all over the world - each and every rug tells a personal story and has an heirloom quality that is rare in this world of fast fashion. It is truly a breath of fresh air to work with Coral and Hive, their ability to pull off custom designs in a variety of fibers is impressive. They continually inspire me to make considered selections for my clients' homes!”</i></p>
                ),
                person: "~ Julia Miller of Yond Interiors"
            },
            {
                msg: (
                    <p><i>“Rugs that not only look and feel luxurious and impeccably stylish, but are also manufactured with lashings of heart and soul.”</i></p>
                ),
                person: "LEIGH ROBERTSON, ELLE DECORATION, SOUTH AFRICA"
            }
        ]

        return(
            <TemplateFourSection>
                <ScrolledIntoView>
                    <SimpleCarousel>
                        {this.renderItems(testimonials)}
                    </SimpleCarousel>
                </ScrolledIntoView>
            </TemplateFourSection>
        )
    }
}

export default Testimonials