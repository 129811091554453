import React from 'react'
import SmallCloseIcon from '../ui/SmallCloseIcon'
import QuantityForm from './QuantityForm'
import {BASE_PATH} from '../../paths'
import {formatPrice} from '../../utils'
import {
  updateCart
} from '../../actions/cart'
import {connect} from 'react-redux'

class CartItem extends React.Component{

    removeItem = id => {
      setTimeout(() => {
        this.props.removeItem(id)
        setTimeout(() => {
          this.props.updateCart()
        }, 300);
      }, 300);
    }

    updateQuantity = (id, qty) => {
      this.props.updateQuantity(id, qty)
      this.props.updateCart()
    }
       
    render(){

      const {id,name,preview_image,price,total,quantity} = this.props.data

        return(
          <tr key={id}>
            <td className='_remove'>
              <p><SmallCloseIcon onClick={() => this.removeItem(id)} /></p>
            </td>
            <td>
              <img className='_item-image' src={BASE_PATH+preview_image} alt="" />
            </td>
            <td className='_product-name'>
              <p>{name}</p>
            </td>
            <td className='_price'>
              <p>£ {formatPrice(price)}</p>
            </td>
            <td className='_quantity'>
              <QuantityForm 
                  id={id}
                  quantity={quantity}
                  onChange={this.updateQuantity}
              />
            </td>
            <td className='_subtotal'>
              <p>£ {formatPrice(total)}</p>
            </td>
          </tr>
        )
    }
}

export default connect(null,{
  updateCart
})(CartItem)