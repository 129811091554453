const ShopBanner = props => {
  
  return (
    <section className='_section'>
      <div className='_grid'>
        <div className='_column'>
          <div className='_shop-banner'>
            <div className='_hr' />
            {props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShopBanner