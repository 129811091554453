import React from 'react'
import {BASE_PATH} from '../../paths'
import ScrolledIntoView from '../../ScrolledIntoView'

class MasonaryGridBlog extends React.Component {

    renderItems = () => {
        return this.props.data.map(({image, image2, alt, caption, id}) => {
            return (
                <ScrolledIntoView key={id}>
                    <div className='_mg_col'>
                        <figure key={id} style={{
                            backgroundImage: image2 ? `url(${BASE_PATH + image2})` : 'none'
                        }}
                            className={`${image2 ? '_animation-image-swith' : ''}`}
                        >
                            <img src={BASE_PATH + image} alt={alt ? alt : ""} />
                            <figcaption>{caption ? caption : ''}</figcaption>
                        </figure>
                    </div>
                </ScrolledIntoView>
            )
        })
    }

    render(){
        return(
            <div className="container _masonary-grid">
                {this.renderItems()}
            </div>
        )
    }
}

export default MasonaryGridBlog