import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import ShopProductGallery from './ShopProductGallery'
import ShopList from './ShopList'
import {connect} from 'react-redux'
import {
  fetchPublicShopCategory,
  fetchPublicShopProduct,
  fetchPublicShopProductGallery,
  fetchPublicShopProductRelated,
} from '../../actions/public-shop'
import {
  addItem
} from '../../actions/cart'
import {formatPrice} from '../../utils'

class ShopProductPage extends React.Component{

    state = {
      qty: 1
    }

    componentDidMount(){
      this.fetchData()
    }

    componentDidUpdate(prevProps){
      if(this.props.match.params.slug !== prevProps.match.params.slug){
        this.fetchData()
        this.setState({qty: 1})
      }
    }

    fetchData = () => {
      this.props.fetchPublicShopCategory(this.props.match.params.categorySlug)
      this.props.fetchPublicShopProduct(this.props.match.params.slug)
      this.props.fetchPublicShopProductGallery(this.props.match.params.slug)
      this.props.fetchPublicShopProductRelated(this.props.match.params.slug)
    }

    minus = () => {
      this.setState({qty: this.state.qty > 1 ? this.state.qty - 1 : 1})
    }

    add = () => {
      this.setState({qty: this.state.qty + 1})
    }

    renderQuantityInput = () => {
      return(
        <div className='_custom-quantity-input'>
          <div className='_minus' onClick={this.minus}>—</div>
          <div className='_display'>{this.state.qty}</div>
          <div className='_plus' onClick={this.add}>+</div>
        </div>
      )
    }

    addToCart = () => {
      this.props.addItem(this.props.match.params.slug, this.state.qty)
      setTimeout(() => {
          this.setState({qty: 1})
      }, 1000);
    }

    renderStockMessage(){
      const {stock_quantity} = this.props.product

      if(stock_quantity > 0 && this.state.qty > stock_quantity)
      return <p><strong><span style={{color: 'green'}}>{stock_quantity} left in stock.</span></strong> More Coming Soon - available as pre-order</p>

      if(stock_quantity >= 5)
      return <p><strong><span style={{color: 'green'}}>In stock</span></strong></p>

      if(stock_quantity === 0)
      return <p><strong>More Stock Soon</strong> - available as pre-order</p>

      if(stock_quantity < 5)
      return <p><strong><span style={{color: 'green'}}>{stock_quantity} in stock</span></strong></p>
    }

    render(){

      if(!this.props.product)
      return <></>

      if(!this.props.category)
      return <></>

      if(!this.props.gallery)
      return <></>

      const {name,price_gbp,information,description, image} = this.props.product

      let gallery = [{path: image}]

      for (let index = 0; index < this.props.gallery.length; index++) {
        gallery = [...gallery, this.props.gallery[index]]
      }

      return(
          <>
          <ScrollToTop />
          <Seo 
              title={`${name && name} | ${this.props.category.name && this.props.category.name} | Shop | Coral & Hive - Natural Handwoven Rugs`}
              description={description}
          />
          <Layout transparent={false}>
            <ScrolledIntoView>
              <Breadcrums>
                <Link to="/">Home</Link>
                <Divider />
                <Link to="/shop">Shop</Link>
                <Divider />
                <Link to={`/shop/${this.props.category.slug && this.props.category.slug}`}>{this.props.category.name && this.props.category.name}</Link>
                <Divider />
                {name && name}
              </Breadcrums>
            <div className='_grid'>
              <div className='_column'>
              <div className='_divider-line' />
              </div>
            </div>
            </ScrolledIntoView>
            <ScrolledIntoView>
              <section className='_section _section-shop-product'>
                <div className='_grid'>
                  <div className='_left-column'>
                    {this.props.gallery && <ShopProductGallery images={gallery} />}
                  </div>
                  <div className='_right-column'>
                    <div className='_sticky'>
                      <div className='_product-details'>
                        <div className='_hr' />

                        <h1 className='_heading-1' style={{marginBottom: "10px"}}>{name && name}</h1>

                        {/* {this.props.category.name && <p style={{marginTop: "10px", marginBottom: "15px", color: '#BBACA3'}}><strong>{this.props.category.name}</strong></p>} */}

                        {this.renderStockMessage()}

                        <div className='_information'>
                          <div dangerouslySetInnerHTML={{__html: information}} />
                        </div>
                        
                        <div className='_heading-1 _price'>£ {formatPrice(price_gbp)}</div>

                      </div>
                      <div className='_product-actions'>

                        <p className='_qty-label'><strong>QUANTITY</strong></p>

                        {this.renderQuantityInput()}

                        <button className='_add-to-cart _button' onClick={this.addToCart}>Add To Cart</button>

                      </div>
                      <div className='_callout' style={{marginTop: '30px'}}>

                        <p style={{marginTop: '0'}}> 
                            <small><span style={{color: '#C1B37F'}}><strong>Note:</strong></span><br />We currently only deliver to the United Kingdom. For enquiries about the rest of the world, please get in touch via our <Link to="/contact" style={{textDecoration: 'underline'}}>contact</Link> page. </small>
                        </p>

                      </div>

                    </div>
                  </div>
                </div>
              </section>
            </ScrolledIntoView>
            <ScrolledIntoView>
              <section className='_section _section-you-may-like-heading'>
                <div className='_grid'>
                  <div className='_column'>
                    <div className='_hr' />
                    <h2>YOU MAY ALSO LIKE</h2>
                  </div>
                </div>
              </section>
            </ScrolledIntoView>
            <ScrolledIntoView>
              {this.props.related && <><ShopList data={this.props.related} colorBox /></>}
              
            </ScrolledIntoView>
          </Layout>
          </>
      )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    category: state.publicShopCategoriesState[ownProps.match.params.categorySlug],
    product: state.publicShopProductsState[ownProps.match.params.slug],
    gallery: state.publicShopProductsGalleryState[ownProps.match.params.slug],
    related: state.publicShopProductsRelatedState[ownProps.match.params.slug]
  }
}

export default connect(mapStateToProps, {
  fetchPublicShopCategory,
  fetchPublicShopProduct,
  fetchPublicShopProductGallery,
  fetchPublicShopProductRelated,
  addItem
})(ShopProductPage)