import _ from 'lodash'
import {
    FETCH_GALLERIES,
    FETCH_GALLERY
} from '../actions/types'

const initialState = {}

const galleriesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_GALLERIES:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case FETCH_GALLERY:
            return {
                ...state, [action.payload.id]: action.payload
            }
        default:
            return state
    }
}

export default galleriesReducer