import React from 'react'
import Layout from '../layout/Layout'
import TemplateBlogArticle from '../ui/TemplateBlogArticle'
import ThemeHeading from '../ui/ThemeHeading'
import {connect} from 'react-redux'
import {
    fetchPublicPostDetails
} from '../../actions'
import {BASE_PATH} from '../../paths'
import ScrolledIntoView from '../../ScrolledIntoView'
import NewsletterSubscription from '../ui/NewsletterSubscription'
import SocialMediaShare from '../ui/SocialMediaShare'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'

class BlogArticle extends React.Component{

    componentDidMount(){
        this.props.fetchPublicPostDetails(this.props.match.params.slug)
    }

    renderPageContent(){
        const {headline, image, body, slug} = this.props.data

        return (
            <>
                <TemplateBlogArticle>
                    <ThemeHeading>
                        <ScrolledIntoView>
                            <h1>{headline}</h1>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            {slug === "creating-healing-spaces-a-unique-approach-to-interior-design-with-be-kin" && (
                                <>
                                <p>
                                    Words: Wren Loucks <br />
                                    Photographs: Athina Kontos
                                </p>
                                <br />
                                </>
                            )}
                            <img src={BASE_PATH + image} alt={headline} />
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <article dangerouslySetInnerHTML={{__html: body}}></article>
                        </ScrolledIntoView>
                    </ThemeHeading>
                </TemplateBlogArticle>
                <SocialMediaShare  
                    data={this.props.data}
                />
                <NewsletterSubscription />
            </>
        )
    }

    renderSeo(){
        const {headline, image, keywords, summary} = this.props.data

        return <Seo 
            title={`${headline} | Coral & Hive - Natural Handwoven Rugs`}
            description={summary}
            keywords={keywords}
            image={BASE_PATH + image}
        />
    }

    render(){

        return(
            <>
                <ScrollToTop />
                {this.props.data && this.renderSeo()}
                <Layout transparent={false}>
                    {this.props.data && this.renderPageContent()}
                </Layout>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.publicPostDetailsState[ownProps.match.params.slug]
    }
}

export default connect(mapStateToProps, {
    fetchPublicPostDetails
})(BlogArticle)