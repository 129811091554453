import React from 'react'
import Layout from '../layout/Layout'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import ShopList from '../shop/ShopList'
import SimpleHeading from '../ui/SimpleHeading'
import CouponField from './CouponField'
import CartItem from './CartItem'
import {connect} from 'react-redux'
import {formatPrice} from '../../utils'
import {
  fetchPublicShopProductRelated,
} from '../../actions/public-shop'
import {
  clearCart,
  updateQuantity,
  removeItem,
  updateCart
} from '../../actions/cart'

class CartPage extends React.Component{

    componentDidMount(){
      this.props.updateCart()
      this.props.fetchPublicShopProductRelated()
    }

    clearCart = () => {
      this.props.clearCart()
    }

    removeItem = id => {
      setTimeout(() => {
        this.props.removeItem(id)
      }, 300);
    }

    updateQuantity = (id, qty) => {
      this.props.updateQuantity(id, qty)
    }

    renderCartItems = () => {

      const cartItems = Object.values(this.props.cartState.items)

      return cartItems.map((item, index) => {
        return (
          <CartItem data={item} key={index} removeItem={this.removeItem} updateQuantity={this.updateQuantity} />
        )
      })
    }

    renderCart = () => {

      const {deliveryCost,discount,subTotalCost,totalCost,items} = this.props.cartState

      const cartItems = Object.values(items)

      if(!cartItems.length > 0) 
      return (
        <section className='_section'>
          <div className='_grid'>
            <div className='_column'>
              <div  className='_cart-empty'>
                <p>{this.props.cartState.expired ? 'Your cart expired' : 'Your cart is empty'}</p>
                <Link to="/shop" className='_button'>Shop Now</Link>
              </div>
            </div>
          </div>
        </section>
      )

      return(
        <>
          <section className='_section _section-shopping-cart'>
            <div className='_grid'>
              <div className='_disclaimers'>
                {this.props.cartState.message && this.renderDisclaimer()}
              </div>
            </div>
            <div className='_grid'>
              <div className='_column'>
                <table className='_cart-items-table'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Product</th>
                      <th></th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderCartItems()}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className='_section _section-cart-actions'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_flex-panel'>
                  <CouponField />
                  <button className='_clear-cart' onClick={this.clearCart}>Clear Cart</button>
                </div>
              </div>
            </div>
          </section>

          <section className='_section _section-cart-totals'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_flex-panel'>
                  <div className='_info'>
                    <p>*Tax Included</p>
                    <p>*Shipping</p>
                    <p>*<Link to="/terms-and-conditions">Returns & Refunds</Link></p>
                    <p>*<Link to="/terms-and-conditions">Terms & Conditions</Link></p>
                  </div>
                  <div className='_cart-totals'>
                    <table>
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td>£ {formatPrice(subTotalCost)}</td>
                        </tr>
                        <tr>
                          <td>Discount</td>
                          <td>£ {formatPrice(discount)}</td>
                        </tr>
                        <tr>
                          <td>Delivery</td>
                          <td>£ {formatPrice(deliveryCost)}</td>
                        </tr>
                        <tr className='_total'>
                          <td>Total</td>
                          <td>£ {formatPrice(totalCost)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Link to="/checkout" className='_button _checkout-button'>Checkout</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )
    }

    renderDisclaimer(){
      return this.props.cartState.message.map((item, index) => {
        return (
          <p className='_disclaimer' key={index}>{item}</p>
        )
      })
    }

    render(){

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="Shopping Cart | Coral & Hive - Natural Handwoven Rugs"
                description=""
            />
            <Layout transparent={false}>
                <Breadcrums>
                  <Link to="/">Home</Link><Divider /><Link to="/shop">Shop</Link><Divider />Cart
                </Breadcrums>
              <SimpleHeading heading="SHOPPING CART" />

              {this.renderCart()}

              {this.props.related && (
                <>
                  <section className='_section _section-you-may-like-heading'>
                    <div className='_grid'>
                      <div className='_column'>
                        <div className='_hr' />
                        <h2>YOU MAY ALSO LIKE</h2>
                      </div>
                    </div>
                  </section>
                  <ShopList data={this.props.related} />
                </>
              )}
              
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
  return {
    cartState: state.cartState,
    related: state.publicShopProductsRelatedState['may_also_like']
  }
}

export default connect(mapStateToProps, {
  fetchPublicShopProductRelated,
  clearCart,
  updateQuantity,
  removeItem,
  updateCart
})(CartPage)