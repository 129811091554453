import _ from 'lodash'
import {
    FETCH_TEAM_BIN,
    RESTORE_TEAM_MEMBER,
    FORCE_DELETE_TEAM_MEMBER
} from '../actions/types'

const initialState = {}

const teamBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_TEAM_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_TEAM_MEMBER:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_TEAM_MEMBER:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default teamBinReducer