import React from 'react'

const ThemeHeading = ({children}) => {
    return(
        <div className="_theme_heading">
            <div className="_hr" />
            {children}
        </div>
    )
}

export default ThemeHeading