import _ from 'lodash'
import {
    FETCH_COUPONS,
    CREATE_COUPON,
    FETCH_COUPON,
    UPDATE_COUPON,
    DELETE_COUPON,
    RESTORE_COUPON
} from '../../actions/types'

const initialState = {}

const couponsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_COUPONS:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_COUPON:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_COUPON:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_COUPON:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_COUPON:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_COUPON:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default couponsReducer