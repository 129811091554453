import {
    SET_CURRENCY
} from './types'

export const setCurrency = currency => {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENCY,
            payload: currency
        })
    }
  }