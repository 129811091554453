import {
    FETCH_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_FAILES,
    SIGNOUT
} from '../actions/types'

const initialState = {}

const myAccountReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ACCOUNT_DETAILS:
            return {
                details: action.payload
            }
        case UPDATE_ACCOUNT_DETAILS:
            return {
                details: action.payload,
                success: 'Account updated successfully'
            }
        case UPDATE_ACCOUNT_DETAILS_FAILES:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case SIGNOUT:
            return initialState
        default:
            return state
    }
}

export default myAccountReducer