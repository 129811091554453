import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import DashboardNav from './DashboardNav'
import {readableDate, formatPrice} from '../../utils'
import { BASE_PATH } from '../../paths'
import placeholder from '../../assets/img/ankara-curly-mohair-and-karakul-wool-blend@2x.jpg'
import {connect} from 'react-redux'
import {
  fetchCustomerOrder
} from '../../actions/customer'

class AccountOrder extends React.Component{

    componentDidMount(){
      this.props.fetchCustomerOrder(this.props.auth.token, this.props.match.params.orderId)
    }

    renderOrderItems(){

      const {items} = this.props.order

      if(!items)
      return

      return items.map(({id, product_image, quantity, product_name, product_price, total}) => {
        return (
          <tr key={id}>
            <td>
              <img src={BASE_PATH + product_image} alt="" style={{width: '80px'}} />
            </td>
            <td>{quantity} x {product_name} - £{formatPrice(product_price)} each</td>
            <td>£{formatPrice(total)}</td>
          </tr>
        )
      })
    }

    render(){

        if(!this.props.order)
        return <></>

        const orderId = this.props.match.params.orderId

        const {   
          billing_name, updated_at, billing_surname, billing_email, billing_phone, billing_country, billing_region, billing_city, billing_address, billing_postal_code, subtotal, discount, freight, total, status, shipping_name, shipping_surname, shipping_street_address, shipping_country, shipping_region, shipping_postal_code, shipping_city } = this.props.order

        

        return(
            <>
            <ScrollToTop />
            <Seo 
                title={`Order #${orderId} | My Orders | My Account | Coral & Hive - Natural Handwoven Rugs`}
                description=""
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                <Link to="/shop">Shop</Link><Divider /><Link to="/my-account/dashboard">My Account</Link><Divider /><Link to="/my-account/orders">My Orders</Link><Divider />Order #{orderId}
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <SimpleHeading heading="My Account" />
              </ScrolledIntoView>
              <ScrolledIntoView>
                <section className='_section _section-account'>
                  <div className='_grid'>
                    <div className='_column'>
                      <div className='_flex-panel'>
                        <DashboardNav />
                        <div className='_account-dashboard-display'>
                          <h3>Order #{orderId}</h3>
                          <p>Order <strong>#{orderId}</strong> was placed on <strong>{readableDate(updated_at)}</strong> and is currently <strong>{status}</strong>.</p> 
                          <h3>ORDER DETAILS</h3>
                          <table className='_customer-orders-table'>
                            <tbody>
                              {this.renderOrderItems()}
                            </tbody>
                          </table>
                          <table className='_customer-orders-table'>
                            <tbody>
                              
                              <tr>
                                <td>
                                  Subtotal
                                </td>
                                <td>
                                £ {formatPrice(subtotal)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Delivery
                                </td>
                                <td>
                                  £ {formatPrice(freight)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Discount
                                </td>
                                <td>
                                  £ {formatPrice(discount)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Total
                                </td>
                                <td>
                                  £ {formatPrice(total)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <div className='_order-details-billing-shipping'>
                            <div>
                              <h3>BILLING DETAILS</h3>
                              <p>
                                {billing_name} {billing_surname}<br />
                                {billing_phone}<br />
                                {billing_email}<br />
                                {billing_country}<br />
                                {billing_region}<br />
                                {billing_city}<br />
                                {billing_address}<br />
                                {billing_postal_code}<br />
                              </p>
                            </div>
                            <div>
                              <h3>SHIPPING ADDRESS</h3>
                              <p>
                                {shipping_name ? shipping_name : billing_name} {shipping_surname ? shipping_surname : billing_surname}<br />
                                {shipping_country ? shipping_country : billing_country}<br />
                                {shipping_region ? shipping_region : billing_region}<br />
                                {shipping_city ? shipping_city : billing_city}<br />
                                {shipping_street_address ? shipping_street_address : billing_address}<br />
                                {shipping_postal_code ? shipping_postal_code : billing_postal_code}
                              </p>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.customerAuthState,
    order: state.customerOrdersState[ownProps.match.params.orderId]
  }
}

export default connect(mapStateToProps, {
  fetchCustomerOrder
})(AccountOrder)