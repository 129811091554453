import _ from 'lodash'
import {
  FETCH_PUBLIC_SHOP_PRODUCTS,
  FETCH_PUBLIC_SHOP_PRODUCT
} from '../actions/types'

const initialState = {}

const publicShopProductsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_SHOP_PRODUCTS:
            return {
              ..._.mapKeys(action.payload, 'slug')
            }
        
        case FETCH_PUBLIC_SHOP_PRODUCT:
            return {
              ...state, [action.payload.slug]: action.payload
            }
        default:
            return state
    }
}

export default publicShopProductsReducer