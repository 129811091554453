import admin from '../apis/admin'

import {
    SEND_CONTACT_EMAIL,
    SEND_CONTACT_EMAIL_FAILED,
    SEND_CONTACT_MAIL_RESET,
    NEWSLETTER_SUBSCRIPTION,
    NEWSLETTER_SUBSCRIPTION_FAILED,
    NEWSLETTER_SUBSCRIPTION_RESET,
    SIGNIN,
    SIGNIN_FAILED,
    SIGNOUT,
    RESET_FORMS,
    FETCH_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_FAILES,
    FETCH_USERS,
    CREATE_USER,
    CREATE_USER_FAILED,
    FETCH_USER,
    UPDATE_USER,
    UPDATE_USER_FAILED,
    DELETE_USER,
    FETCH_USERS_BIN,
    RESTORE_USER,
    FORCE_DELETE_USER,
    FETCH_PRODUCTS,
    CREATE_PRODUCT,
    CREATE_PRODUCT_FAILED,
    FETCH_PRODUCT,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_FAILED,
    DELETE_PRODUCT,
    RESTORE_PRODUCT,
    FETCH_PRODUCTS_BIN,
    FORCE_DELETE_PRODUCT,
    FETCH_PRODUCT_GALLERY,
    CREATE_PRODUCT_GALLERY_IMAGE,
    CREATE_PRODUCT_GALLERY_IMAGE_FAILED,
    FETCH_PRODUCT_GALLERY_IMAGE,
    UPDATE_PRODUCT_GALLERY_IMAGE,
    UPDATE_PRODUCT_GALLERY_IMAGE_FAILED,
    DELETE_PRODUCT_GALLERY_IMAGE,
    RESTORE_PRODUCT_GALLERY_IMAGE,
    FETCH_PRODUCT_GALLERY_BIN,
    FORCE_DELETE_PRODUCT_GALLERY_IMAGE,
    FETCH_POSTS,
    CREATE_POST,
    CREATE_POST_FAILED,
    FETCH_POST,
    UPDATE_POST,
    UPDATE_POST_FAILED,
    DELETE_POST,
    RESTORE_POST,
    FETCH_POSTS_BIN,
    FORCE_DELETE_POST,
    FETCH_MEDIA,
    FETCH_PUBLIC_PRODUCTS,
    FETCH_PUBLIC_PRODUCT_DETAILS,
    FETCH_PUBLIC_POSTS,
    FETCH_PUBLIC_POST_DETAILS,
    ACCEPT_COOKIE,
    FETCH_GALLERIES,
    FETCH_GALLERY,
    FETCH_GALLERY_IMAGES,
    CREATE_GALLERY_IMAGE,
    CREATE_GALLERY_IMAGE_FAILED,
    FETCH_GALLERY_IMAGE,
    UPDATE_GALLERY_IMAGE,
    UPDATE_GALLERY_IMAGE_FAILED,
    DELETE_GALLERY_IMAGE,
    FETCH_PUBLIC_GALLERY_IMAGES,
    FETCH_TEAM,
    CREATE_TEAM_MEMBER,
    CREATE_TEAM_MEMBER_FAILED,
    FETCH_TEAM_MEMBER,
    UPDATE_TEAM_MEMBER,
    UPDATE_TEAM_MEMBER_FAILED,
    DELETE_TEAM_MEMBER,
    RESTORE_TEAM_MEMBER,
    FETCH_TEAM_BIN,
    FORCE_DELETE_TEAM_MEMBER,
    FETCH_PUBLIC_TEAM,
    POPUP_HIDE,
    POPUP_NEWSLETTER_HIDE
} from './types'


export const hidePopup = () => {
    return (dispatch) => {
        dispatch({
            type: POPUP_HIDE
        })
    }
  }

export const hideNewsletterPopup = () => {
    return (dispatch) => {
        dispatch({
            type: POPUP_NEWSLETTER_HIDE
        })
    }
}

export const sendContactEmail = formValues => async dispatch => {
    try {

        const response = await admin.post('send-contact', formValues)

        dispatch({
            type: SEND_CONTACT_EMAIL,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SEND_CONTACT_EMAIL_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const resetSendContactEmail = () => {
    return (dispatch) => {
        dispatch({
            type: SEND_CONTACT_MAIL_RESET
        })
    }
}

export const newsletterSubscription = formValues => async dispatch => {
    try {
        const response = await admin.post('newsletter-subscription', formValues)

        dispatch({
            type: NEWSLETTER_SUBSCRIPTION,
            payload: response.data
        })
    } catch(error){
        dispatch({
            type: NEWSLETTER_SUBSCRIPTION_FAILED,
            payload: error.response ? error.response.data : null
        })       
    }
}

export const resetNewsletterSubscription = () => {
    return (dispatch) => {
        dispatch({
            type: NEWSLETTER_SUBSCRIPTION_RESET
        })
    }
}

export const signIn = formValues => async dispatch => {
    try {

        const response = await admin.post('user/login', formValues)

        dispatch({
            type: SIGNIN,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SIGNIN_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signOut = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('user/logout', {}, config)

        dispatch({
            type: SIGNOUT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SIGNOUT,
            payload: error.response ? error.response.data : null
        })
    }
}

export const resetForms = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FORMS
        })
    }
}

export const fetchAccountDetails = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('user/details', config)

        dispatch({
            type: FETCH_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch account details failed')
    }
}

export const updateAccountDetails = (token, formValues) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('user/details/update', formValues, config)

        dispatch({
            type: UPDATE_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_ACCOUNT_DETAILS_FAILES,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUsers = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('users', config)

        dispatch({
            type: FETCH_USERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching users failed')
    }
}

export const createUser = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('users/create', formValues, config)

        dispatch({
            type: CREATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('users/'+id, config)

        dispatch({
            type: FETCH_USER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching user failed')
    }
}


export const updateUser = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('users/'+id+'/update', formValues, config)

        dispatch({
            type: UPDATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const deleteUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('users/'+id+'/delete', {}, config)

        dispatch({
            type: DELETE_USER,
            payload: id
        })
        
    } catch (error) {
        console.log('delete user failed')
    }
}

export const fetchUsersBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('users/bin', config)

        dispatch({
            type: FETCH_USERS_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching users bin failed')
    }
}

export const restoreUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('users/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_USER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('restore user failed')
    }
}

export const forceDeleteUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('users/'+id+'/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_USER,
            payload: id
        })
        
    } catch (error) {
        console.log('delete user failed')
    }
}

export const fetchProducts = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('products', config)

        dispatch({
            type: FETCH_PRODUCTS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch products failed')
    }
}

export const createProduct = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('products/create', formValues, config)

        dispatch({
            type: CREATE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_PRODUCT_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('products/' + id, config)

        dispatch({
            type: FETCH_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch product failed')
    }
}

export const updateProduct = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('products/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('products/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_PRODUCT,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete product failed')        
    }
}

export const fetchProductsBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('products/bin', config)

        dispatch({
            type: FETCH_PRODUCTS_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch products bin failed')
    }
}

export const restoreProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('products/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Product restore failed')
    }
}

export const forceDeleteProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('products/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_PRODUCT,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete product failed')        
    }
}

export const fetchProductGallery = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('products/' + id + '/gallery', config)

        dispatch({
            type: FETCH_PRODUCT_GALLERY,
            payload: response.data,
            id
        })
        
    } catch (error) {
        console.log('Fetch product gallery failed')        
    }
}

export const addNewProductGalleryImage = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('products/' + id + '/gallery/create', formValues, config)

        dispatch({
            type: CREATE_PRODUCT_GALLERY_IMAGE,
            payload: response.data,
            id
        })
        
    } catch (error) {
        
        dispatch({
            type: CREATE_PRODUCT_GALLERY_IMAGE_FAILED,
            payload: error.response ? error.response.data : null
        })   
    }
}

export const fetchProductGalleryImage = (token, id, image_id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('products/' + id + '/gallery/' + image_id, config)

        dispatch({
            type: FETCH_PRODUCT_GALLERY_IMAGE,
            payload: response.data,
            id
        })
        
    } catch (error) {
        console.log('Fetch image failed')
    }
}

export const updateProductGalleryImage = (token, id, image_id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('products/' + id + '/gallery/' + image_id + '/update', formValues, config)

        dispatch({
            type: UPDATE_PRODUCT_GALLERY_IMAGE,
            payload: response.data,
            id
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_GALLERY_IMAGE_FAILED,
            payload: error.response ? error.response.data : null
        }) 
    }
}

export const deleteProductGalleryImage = (token, id, image_id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('products/' + id + '/gallery/' + image_id + '/delete', {}, config)

        dispatch({
            type: DELETE_PRODUCT_GALLERY_IMAGE,
            id,
            image_id           
        })
        
    } catch (error) {
        console.log('Image delete failed')
    }
}

export const fetchProductGalleryBin = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('products/' + id + '/gallery/bin', config)

        dispatch({
            type: FETCH_PRODUCT_GALLERY_BIN,
            payload: response.data,
            id       
        })
        
    } catch (error) {
        console.log('Fetch gallery bin failed')
    }
}

export const restoreProductGalleryImage = (token, id, image_id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('products/' + id + '/gallery/' + image_id + '/restore', {}, config)

        dispatch({
            type: RESTORE_PRODUCT_GALLERY_IMAGE,
            payload: response.data,
            id       
        })
        
    } catch (error) {
        console.log('Image restore failed')
    }
}

export const forceDeleteProductGalleryImage = (token, id, image_id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('products/' + id + '/gallery/' + image_id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_PRODUCT_GALLERY_IMAGE,
            image_id,
            id       
        })
        
    } catch (error) {
        console.log('Image delete failed')
    }
}


export const fetchPosts = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('posts', config)

        dispatch({
            type: FETCH_POSTS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch posts failed')
    }
}

export const createPost = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('posts/create', formValues, config)

        dispatch({
            type: CREATE_POST,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_POST_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchPost = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('posts/' + id, config)

        dispatch({
            type: FETCH_POST,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch post failed')
    }
}

export const updatePost = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('posts/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_POST,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_POST_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deletePost = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('posts/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_POST,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete post failed')        
    }
}

export const fetchPostsBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('posts/bin', config)

        dispatch({
            type: FETCH_POSTS_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch posts bin failed')
    }
}

export const restorePost = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('posts/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_POST,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Post restore failed')
    }
}

export const forceDeletePost = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('posts/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_POST,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete post failed')        
    }
}

export const fetchMedia = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('media', config)

        dispatch({
            type: FETCH_MEDIA,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch media failed') 
    }
}

export const fetchPublicProducts = () => async dispatch => {
    try {

        const response = await admin.get('views/products')

        dispatch({
            type: FETCH_PUBLIC_PRODUCTS,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch products failed') 
    }
}

export const fetchPublicProductDetails = slug => async dispatch => {
    try {

        const response = await admin.get('views/products/' + slug)

        dispatch({
            type: FETCH_PUBLIC_PRODUCT_DETAILS,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch product details failed') 
    }
}

export const fetchPublicPosts = () => async dispatch => {
    try {

        const response = await admin.get('views/blog')

        dispatch({
            type: FETCH_PUBLIC_POSTS,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch posts failed') 
    }
}

export const fetchPublicPostDetails = slug => async dispatch => {
    try {

        const response = await admin.get('views/blog/' + slug)

        dispatch({
            type: FETCH_PUBLIC_POST_DETAILS,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch post details failed') 
    }
}

export const acceptCookie = () => {
    return (dispatch) => {
        dispatch({
            type: ACCEPT_COOKIE
        })
    }
}

export const fetchGalleries = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.get('galleries', config)

        dispatch({
            type: FETCH_GALLERIES,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch galleries failed') 
    }
}

export const fetchGallery = (token, id) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.get('galleries/' + id, config)

        dispatch({
            type: FETCH_GALLERY,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch galleries failed') 
    }
}

export const fetchGalleryImages = (token, id) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.get(`galleries/${id}/images`, config)

        dispatch({
            type: FETCH_GALLERY_IMAGES,
            payload: response.data,
            id
        })
        
    } catch (error) {
        console.log('Fetch gallery images failed') 
    }
}

export const createGalleryImage = (token, id, formValues) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.post(`galleries/${id}/images/create`, formValues, config)

        dispatch({
            type: CREATE_GALLERY_IMAGE,
            payload: response.data,
            id
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_GALLERY_IMAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchGalleryImage = (token, id, imageId) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.get(`galleries/${id}/images/${imageId}`, config)

        dispatch({
            type: FETCH_GALLERY_IMAGE,
            payload: response.data,
            id
        })
        
    } catch (error) {
        console.log('Fetch gallery image failed') 
    }
}

export const updateGalleryImage = (token, id, imageId,formValues) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.post(`galleries/${id}/images/${imageId}/update`, formValues, config)

        dispatch({
            type: UPDATE_GALLERY_IMAGE,
            payload: response.data,
            id
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_GALLERY_IMAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const deleteGalleryImage = (token, id, imageId) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.post(`galleries/${id}/images/${imageId}/delete`, {}, config)

        dispatch({
            type: DELETE_GALLERY_IMAGE,
            payload: response.data,
            id,
            imageId
        })
        
    } catch (error) {
        console.log('Delete gallery image failed') 
    }
}

export const fetchPublicGalleryImages = slug => async dispatch => {
    try {

        const response = await admin.get('views/gallery/'+ slug)

        dispatch({
            type: FETCH_PUBLIC_GALLERY_IMAGES,
            payload: response.data,
            slug
        })
        
    } catch (error) {
        console.log('Fetch gallery images failed') 
    }
}

export const fetchTeam = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('team', config)

        dispatch({
            type: FETCH_TEAM,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch team failed')
    }
}

export const createTeamMember = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('team/create', formValues, config)

        dispatch({
            type: CREATE_TEAM_MEMBER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_TEAM_MEMBER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchTeamMember = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('team/' + id, config)

        dispatch({
            type: FETCH_TEAM_MEMBER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch team member failed')
    }
}

export const updateTeamMember = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('team/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_TEAM_MEMBER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_TEAM_MEMBER_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteTeamMember = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('team/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_TEAM_MEMBER,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete team member failed')        
    }
}

export const fetchTeamBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('team/bin', config)

        dispatch({
            type: FETCH_TEAM_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch team bin failed')
    }
}

export const restoreTeamMember = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('team/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_TEAM_MEMBER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Team member restore failed')
    }
}

export const forceDeleteTeamMember = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('team/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_TEAM_MEMBER,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete team member failed')        
    }
}

export const fetchPublicTeam = () => async dispatch => {
    try {

        const response = await admin.get('views/team')
        console.log(response.data)

        dispatch({
            type: FETCH_PUBLIC_TEAM,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch team failed') 
    }
}