import {
    SEND_CONTACT_EMAIL,
    SEND_CONTACT_EMAIL_FAILED,
    SEND_CONTACT_MAIL_RESET
} from '../actions/types'

const initialState = {}

const sendContactMailReducer = (state = {}, action) => {
    switch(action.type){
        case SEND_CONTACT_EMAIL:
            return {
                success: action.payload
            }
        case SEND_CONTACT_EMAIL_FAILED:
            return {
                errors: action.payload
            }
        case SEND_CONTACT_MAIL_RESET:
            return initialState
        default:
            return state
    }
}

export default sendContactMailReducer