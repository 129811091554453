import React from 'react'
import {Link} from 'react-router-dom'
import SocialIcons from '../ui/SocialIcons'
import Logo from '../ui/Logo'
import {connect} from 'react-redux'
import Copyright from '../ui/Copyright'

class Footer extends React.Component {

    state = {
        value: ""
    }

    scrollToPosition = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0, // scroll so that the element is at the top of the view
                // behavior: 'smooth' // smooth scroll
            })
        },300)    
    }

    render(){

        return(
            <footer className="_footer">
                <div className="_box">
                    <div className="_item">
                        <ul>
                            <li>
                                <Link to="/">HOME</Link>
                            </li>
                            <li>
                                <Link to="/about">ABOUT US</Link>
                            </li>
                            {
                                this.props.product && (
                                    <li>
                                        <Link to={`/rugs/${this.props.product.slug}`} onClick={() => this.scrollToPosition()}>RUGS</Link>
                                    </li>
                                )
                            }
                            <li>
                                <Link to="/shop/cushions">CUSHIONS & THROWS</Link>
                            </li>
                            
                            
                        </ul>                       
                    </div>
                    <div className="_item">
                        <ul>
                            <li>
                                <Link to="/colours-and-patterns">COLOURS & PATTERNS</Link>
                            </li>
                            <li>
                                <Link to="/gallery">GALLERY</Link>
                            </li>
                            <li>
                                <Link to="/studio-samples">STUDIO SAMPLES</Link>
                            </li>   
                            <li>
                                <Link to="/blog">BLOG</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="_item">
                        <ul>
                            
                            <li>
                                <Link to="/contact">CONTACT</Link>
                            </li>
                            <li>
                                <Link to="/shop">SHOP</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">PRIVACY POLICY</Link>
                            </li>
                            <li>
                                <Link to="/terms-and-conditions">TERMS & CONDITIONS</Link>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="_item">
                        <h4 style={{marginBottom: '8px'}}>SIGN UP FOR EMAILER</h4>
                        <p style={{marginBottom: '20px', marginTop: '8px'}}>Promotions, new products and sales. Directly to your inbox.</p>
                        <Link to="/newsletter-subscription" className="_subscribe-button">Subscribe</Link>
                    </div> */}
                </div>
                <div className="_box">
                    <div className="_item">
                        <SocialIcons fill="#fff" />
                    </div>
                    
                    <div className="_item">
                        <Logo link="/contact" />
                    </div>   

                    <div className="_item">
                        <Copyright />
                    </div>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = state => {
    return {
        product: Object.values(state.publicProductsState)[0]
    }
}

export default connect(mapStateToProps)(Footer)