import React, {useState}  from 'react'
import {Link} from 'react-router-dom'
import {BASE_PATH} from '../../paths'
import {formatPrice} from '../../utils'
import {
  addItem
} from '../../actions/cart'
import {connect} from 'react-redux'

const ShopItem = props => {

  const [qty, setQty] = useState(1)

  const minus = () => {
    setQty(qty > 1 ? qty - 1 : 1)
  }

  const add = () => {
    setQty(qty + 1)
  }

  const addToCart = e => {
    props.addItem(props.data.slug , qty)
    setTimeout(() => {
      setQty(1)
    }, 1000)
  }

  const renderStockMessage = () => {

    const {stock_quantity} = props.data

    if(stock_quantity === 0)
    return <p><strong>More Stock Soon</strong><br />available as pre-order</p>

    if(stock_quantity < 5)
    return <p><strong><span style={{color: 'green'}}>{stock_quantity} in stock</span></strong></p>
  }

  const {categorySlug} = props
  const {name, description, image, slug, price_gbp, category_slug} = props.data

  if(!name && description && image && slug && categorySlug)
  return <></>

  return (
    <div className='_column _shop-item'>
      <Link to={`/shop/${category_slug}/${slug}`}>
        <div className='_image'>
          <div className='_inner' style={{
            backgroundImage: `url(${BASE_PATH+image})`
          }} />
        </div>
        <h3>{name}</h3>
        <p>{description}</p>
        {renderStockMessage()}
        <div className='_price'>£ {formatPrice(price_gbp)}</div>
        
      </Link>
      <br />
      <div className='_flex'>
        <div className='_custom-quantity-input'>
          <div className='_minus' onClick={minus}>—</div>
          <div className='_display'>{qty}</div>
          <div className='_plus' onClick={add}>+</div>
        </div>
        <button className='_button' onClick={e => addToCart()}>Add To Cart</button>
      </div>
    </div>
  )
}

export default connect(null, {
  addItem
})(ShopItem)