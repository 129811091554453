import _ from 'lodash'
import {
    FETCH_PRODUCT_GALLERY,
    CREATE_PRODUCT_GALLERY_IMAGE,
    FETCH_PRODUCT_GALLERY_IMAGE,
    UPDATE_PRODUCT_GALLERY_IMAGE,
    DELETE_PRODUCT_GALLERY_IMAGE,
    RESTORE_PRODUCT_GALLERY_IMAGE
} from '../actions/types'

const initialState = {}

const productGalleryReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PRODUCT_GALLERY:
            return {
                ...state, [action.id]: {..._.mapKeys(action.payload, 'id')}
            }
        case CREATE_PRODUCT_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }
        case FETCH_PRODUCT_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }
        case UPDATE_PRODUCT_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }   
        case RESTORE_PRODUCT_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            } 
        case DELETE_PRODUCT_GALLERY_IMAGE:
            return {
                ...state, [action.id]: _.omit(state[action.id], action.image_id)
            }
        default:
            return state
    }
}

export default productGalleryReducer