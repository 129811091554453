import React from 'react'
import {Link} from 'react-router-dom'

const Logo = ({fill, link}) => {
    return(
        <Link to={link ? link  : '/'} className="_logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 120">
                <g id="CoralandHive_Logo" data-name="CoralandHive Logo" transform="translate(-35.045 -20.784)">
                    <g id="Group_216" data-name="Group 216" transform="translate(35.045 104.732)">
                    <g id="Group_214" data-name="Group 214" transform="translate(0 0.082)">
                        <path id="Path_105" data-name="Path 105" d="M42.028,219.465c-5.128,0-6.983-3.982-6.983-9.247,0-4.91,1.664-9.11,7.146-9.11a12.649,12.649,0,0,1,4.119.628l-.491,1.145a9.006,9.006,0,0,0-3.628-.764c-4.691,0-5.837,3.983-5.837,8.074,0,4.337,1.255,8.265,5.674,8.265a10.479,10.479,0,0,0,3.982-.9L46.5,218.7a12.867,12.867,0,0,1-4.473.763" transform="translate(-35.045 -201.108)" fill={fill ? fill : "#fff"}/>
                        <path id="Path_106" data-name="Path 106" d="M77.676,202.117c-4.691,0-5.919,3.464-5.919,8.155,0,4.583,1.228,8.183,5.919,8.183s5.837-3.6,5.837-8.183c0-4.691-1.146-8.155-5.837-8.155m0,17.348c-5.592,0-7.228-3.955-7.228-9.192s1.882-9.164,7.255-9.164c5.537,0,7.119,3.928,7.119,9.164s-1.827,9.192-7.146,9.192" transform="translate(-53.916 -201.108)" fill={fill ? fill : "#fff"}/>
                        <path id="Path_107" data-name="Path 107" d="M120.59,202.7h-3.846v7.473h3.873c3.082,0,3.955-1.718,3.955-3.927,0-2.4-1.445-3.546-3.983-3.546m4.61,16.638-6.055-8.156h-2.4v8.156H115.49V201.693h4.964c2.809,0,5.428,1.118,5.428,4.555,0,2.672-1.255,4.91-5.292,4.91l6.138,8.182Z" transform="translate(-77.926 -201.42)" fill={fill ? fill : "#fff"}/>
                    </g>
                    <path id="Path_108" data-name="Path 108" d="M156.493,203.547l-3.682,9.3h7.037Zm5.728,15.793-2.045-5.537h-7.747l-2.181,5.537h-1.283l7.174-17.647h.845l6.656,17.647Z" transform="translate(-95.82 -201.338)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_109" data-name="Path 109" d="M192.954,219.34V201.693h1.255v16.556h8.428v1.091Z" transform="translate(-119.286 -201.338)" fill={fill ? fill : "#fff"}/>
                    <g id="Group_215" data-name="Group 215" transform="translate(91.11)">
                        <path id="Path_110" data-name="Path 110" d="M234.816,201.833c-2.019,0-2.7,1.419-2.7,3.573,0,1.745,1.719,2.7,2.564,3.328,1.418-.572,3.028-1.5,3.028-3.655,0-2.019-.682-3.246-2.891-3.246m4.664,17.1-.327-1.582a3.948,3.948,0,0,1-3.492,1.936c-3.109,0-5.318-1.745-5.318-4.964a5.274,5.274,0,0,1,3.382-5.128c-1.009-.764-2.836-1.664-2.836-3.765,0-2.481.982-4.5,4.009-4.5,2.509,0,4.009,1.2,4.009,4.173,0,2.182-1.036,3.3-3.791,4.473-1.991.846-3.573,1.828-3.573,4.691a3.911,3.911,0,0,0,4.118,4.119,4.3,4.3,0,0,0,3.383-2.291v-3.928h-3.029v-.9h4.147v3.846c0,1.582.163,3.028.218,3.818Z" transform="translate(-230.343 -200.933)" fill={fill ? fill : "#fff"}/>
                    </g>
                    <path id="Path_111" data-name="Path 111" d="M288.674,219.34v-8.592h-9.056v8.592h-1.254V201.693h1.254v8.019h9.056v-8.019h1.255V219.34Z" transform="translate(-164.852 -201.338)" fill={fill ? fill : "#fff"}/>
                    <rect id="Rectangle_30" data-name="Rectangle 30" width="1.255" height="17.647" transform="translate(132.923 0.355)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_112" data-name="Path 112" d="M340.82,219.34h-1.036L333.1,201.693h1.391l5.865,15.738,5.564-15.738h1.309Z" transform="translate(-194.071 -201.338)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_113" data-name="Path 113" d="M374.521,219.34V201.693H383.9V202.7h-8.128v7.01h7.555v1.01h-7.555v7.465l8.374.061v1.094Z" transform="translate(-216.149 -201.338)" fill={fill ? fill : "#fff"}/>
                    </g>
                    <path id="Path_114" data-name="Path 114" d="M179.209,20.784,147.775,38.932v36.3l31.434,18.149,31.434-18.149v-36.3ZM165.321,61.441l2.125-9.191,8.84,3.773-5.642,5.886Zm4.246,1.593-6.081,6.344,1.559-6.742ZM148.989,44.372l17.323,7.394L164.1,61.334,148.989,60Zm24.6-18.943,1.056-.61L186.7,45.164l-4.043,4.218L170.618,38.532l3.027-13.091ZM169.586,37.6l-6.669-6.012,9.3-5.37Zm39.843,31.248-6.809-2.906,6.809.6Zm-12.745-6.824L183.556,50.194l3.783-3.946Zm-8.473-16.689,3.538-3.691,5.52,18.986Zm7.945,17.847L178.378,55.6l4.338-4.526ZM181.814,50.258l-4.629,4.829-9.461-4.038,2.581-11.164Zm-4.334,6.275,17.646,7.532-22.931-2.019Zm24.308,11.728,7.222,6.509L206.5,76.219Zm3.662,8.565-1.89,1.091L201.113,69.5Zm-3.065-9.661,7.045,3.007v3.342Zm7.045-1.839-9.781-.862-6.929-23.829,6.571-6.855,10.139,5.853Zm-17.1-26.037L189,27.838l9.21,5.317Zm-4.712-12.25-.1.03L191.358,40.3l-3.789,3.953L175.7,24.212l3.51-2.027Zm-25.8,5.191,7.462,6.726L166.59,50.565l-17.6-7.513V39.633ZM148.989,61.222l14.834,1.307-1.98,8.563-6.7,6.99-6.154-3.553ZM161.309,73.4l-1.681,7.267-3.4-1.964Zm-.581,7.9,2.224-9.615,8.167-8.52,27.6,2.43,3.763,12.941L179.209,91.976Z" transform="translate(-60.164)" fill={fill ? fill : "#fff"}/>
                    <g id="Group_217" data-name="Group 217" transform="translate(46.399 133.536)">
                    <path id="Path_115" data-name="Path 115" d="M63.42,269.818v-3.275H60.145v3.275h-.784v-6.947h.784v3.049H63.42v-3.049H64.2v6.947Z" transform="translate(-59.361 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_116" data-name="Path 116" d="M77.955,269.818l-.741-2.073H74.359l-.784,2.073h-.827l2.824-6.947h.644l2.62,6.947Zm-2.1-5.96-1.267,3.3H77.01Z" transform="translate(-66.497 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_117" data-name="Path 117" d="M92.772,269.818,89.5,263.794v6.024h-.719v-6.947h1.116l3.254,6.024v-6.024h.763v6.947Z" transform="translate(-75.042 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_118" data-name="Path 118" d="M105.976,269.818h-1.7v-6.947h1.7c2.405,0,3.318.945,3.318,3.447C109.295,268.841,108.328,269.818,105.976,269.818Zm0-6.336h-.913v5.723h1.01c1.632,0,2.405-.869,2.405-2.878C108.478,264.16,107.78,263.483,105.976,263.483Z" transform="translate(-83.305 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_119" data-name="Path 119" d="M124.116,269.818H123.2l-1.568-5.519-1.654,5.519h-.934l-1.75-6.947h.827l1.482,5.906,1.632-5.616h.913l1.535,5.627,1.525-5.917h.752Z" transform="translate(-90.273 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_120" data-name="Path 120" d="M140.58,269.866c-2.309,0-3-1.557-3-3.629,0-2.051.784-3.618,3.006-3.618,2.255,0,2.942,1.535,2.942,3.618C143.533,268.3,142.781,269.866,140.58,269.866Zm0-6.635c-1.654,0-2.18,1.17-2.18,3.006s.526,3.017,2.18,3.017c1.675,0,2.136-1.235,2.136-3.017C142.716,264.39,142.223,263.23,140.58,263.23Z" transform="translate(-101.096 -262.618)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_121" data-name="Path 121" d="M155.231,269.818h-.687l-2.63-6.947h.87l2.126,5.884,2.019-5.884h.837Z" transform="translate(-108.741 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_122" data-name="Path 122" d="M167.369,269.818v-6.947h3.844v.612h-3.06v2.438h2.835v.613h-2.835v2.673h3.157v.612Z" transform="translate(-116.987 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_123" data-name="Path 123" d="M183.691,269.818l-3.275-6.024v6.024H179.7v-6.947h1.116l3.254,6.024v-6.024h.763v6.947Z" transform="translate(-123.565 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_124" data-name="Path 124" d="M204.522,269.818l-.741-2.073h-2.856l-.784,2.073h-.827l2.824-6.947h.644l2.62,6.947Zm-2.1-5.96-1.266,3.3h2.426Z" transform="translate(-134.032 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_125" data-name="Path 125" d="M219.147,269.818l-2.287-3.1h-.73v3.1h-.784v-6.947h2.169c1.106,0,2.212.419,2.212,1.868a1.79,1.79,0,0,1-1.955,1.954l2.341,3.125Zm-1.578-6.336H216.13V266.1h1.449a1.2,1.2,0,0,0,1.342-1.364C218.921,263.923,218.438,263.483,217.569,263.483Z" transform="translate(-142.585 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_126" data-name="Path 126" d="M230.026,263.494v6.324h-.784v-6.324h-2.051v-.623h4.9v.623Z" transform="translate(-148.904 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_127" data-name="Path 127" d="M240.254,269.818v-6.947h.784v6.947Z" transform="translate(-155.871 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_128" data-name="Path 128" d="M247.343,269.866a7.455,7.455,0,0,1-1.868-.311l.269-.687a4.6,4.6,0,0,0,1.611.387c.827,0,1.718-.279,1.718-1.353,0-.934-.859-1.2-1.75-1.524-.923-.333-1.847-.731-1.847-1.943,0-1.31,1.171-1.815,2.448-1.815a5.789,5.789,0,0,1,1.718.279l-.268.687a3.283,3.283,0,0,0-1.461-.354c-.988,0-1.632.387-1.632,1.2,0,.687.633.955,1.364,1.224,1.031.365,2.233.73,2.233,2.223C249.877,269.426,248.707,269.866,247.343,269.866Z" transform="translate(-158.659 -262.618)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_129" data-name="Path 129" d="M262.5,269.818l-.741-2.073h-2.856l-.784,2.073H257.3l2.824-6.947h.644l2.62,6.947Zm-2.1-5.96-1.267,3.3h2.426Z" transform="translate(-164.968 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_130" data-name="Path 130" d="M277.323,269.818l-3.275-6.024v6.024h-.719v-6.947h1.116l3.254,6.024v-6.024h.763v6.947Z" transform="translate(-173.521 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_131" data-name="Path 131" d="M292.543,269.818l-.741-2.073h-2.856l-.784,2.073h-.827l2.824-6.947h.644l2.62,6.947Zm-2.1-5.96-1.267,3.3H291.6Z" transform="translate(-180.994 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_132" data-name="Path 132" d="M303.367,269.818v-6.947h.784v6.3h3.178v.644Z" transform="translate(-189.546 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_133" data-name="Path 133" d="M323.7,269.818l-2.287-3.1h-.73v3.1H319.9v-6.947h2.168c1.106,0,2.212.419,2.212,1.868a1.79,1.79,0,0,1-1.955,1.954l2.341,3.125Zm-1.578-6.336h-1.439V266.1h1.449a1.2,1.2,0,0,0,1.342-1.364C323.478,263.923,323,263.483,322.126,263.483Z" transform="translate(-198.39 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_134" data-name="Path 134" d="M336.067,270c-1.89,0-2.663-.891-2.663-2.749v-4.381h.784v4.274c0,1.772.6,2.244,1.879,2.244,1.192,0,1.729-.591,1.729-2.244v-4.274h.784v4.4C338.579,269.12,337.774,270,336.067,270Z" transform="translate(-205.586 -262.753)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_135" data-name="Path 135" d="M351.113,269.866c-2.319,0-2.921-1.782-2.921-3.661,0-2.137.849-3.587,3.017-3.587a7,7,0,0,1,1.6.258l-.269.687a3.293,3.293,0,0,0-1.321-.332c-1.5,0-2.212.955-2.212,2.975,0,1.675.418,3.049,2.137,3.049a2.879,2.879,0,0,0,1.385-.43V266.87h-1.535v-.623h2.244v3.017A4.218,4.218,0,0,1,351.113,269.866Z" transform="translate(-213.469 -262.618)" fill={fill ? fill : "#fff"}/>
                    <path id="Path_136" data-name="Path 136" d="M363.883,269.866a7.455,7.455,0,0,1-1.868-.311l.268-.687a4.6,4.6,0,0,0,1.611.387c.827,0,1.717-.279,1.717-1.353,0-.934-.859-1.2-1.75-1.524-.923-.333-1.847-.731-1.847-1.943,0-1.31,1.171-1.815,2.448-1.815a5.789,5.789,0,0,1,1.718.279l-.268.687a3.283,3.283,0,0,0-1.461-.354c-.988,0-1.632.387-1.632,1.2,0,.687.633.955,1.364,1.224,1.031.365,2.233.73,2.233,2.223C366.417,269.426,365.247,269.866,363.883,269.866Z" transform="translate(-220.837 -262.618)" fill={fill ? fill : "#fff"}/>
                    </g>
                </g>
            </svg>
        </Link>
    )
}

export default Logo