import React from 'react'
import {Link} from 'react-router-dom'
import ScrolledIntoView from '../../ScrolledIntoView'

class GetInTouch extends React.Component {

    render(){
        return(           
            <section className={`_section _center _padding-bottom ${this.props.className ? this.props.className : ''}`}>
                <div className="_box">
                    <div className="_column">
                        <ScrolledIntoView>
                            <p style={{
                                marginTop: '0'
                            }}>Get in touch and let's create something beautiful for you.</p>
                            <Link to="/contact" className="_button">Get In Touch</Link>
                        </ScrolledIntoView>
                    </div>
                </div>
            </section>
        )
    }
}

export default GetInTouch