// import {combineReducers} from 'redux'
import {persistCombineReducers} from 'redux-persist'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import CookieStorage from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'

import sendContactMailReducer from './sendContactMailReducer'
import newsletterSubscriptionReducer from './newsletterSubscriptionReducer'
import authReducer from './authReducer'
import myAccountReducer from './myAccountReducer'
import usersReducer from './usersReducer'
import createUserReducer from './createUserReducer'
import updateUserReducer from './updateUserReducer'
import usersBinReducer from './usersBinReducer'
import productsReducer from './productsReducer'
import createProductReducer from './createProductReducer'
import updateProductReducer from './updateProductReducer'
import productsBinReducer from './productsBinReducer'
import productGalleryReducer from './productGalleryReducer'
import addNewProductGalleryImageReducer from './addNewProductGalleryImageReducer'
import updateProductGalleryImageReducer from './updateProductGalleryImageReducer'
import productGalleryBinReducer from './productGalleryBinReducer'
import postsReducer from './postsReducer'
import createPostReducer from './createPostReducer'
import updatePostReducer from './updatePostReducer'
import postsBinReducer from './postsBinReducer'
import mediaReducer from './mediaReducer'
import publicProductsReducer from './publicProductsReducer'
import publicPostsReducer from './publicPostsReducer'
import publicProductDetailsReducer from './publicProductDetailsReducer'
import publicPostDetailsReducer from './publicPostDetailsReducer'
import cookieReducer from './cookieReducer'
import galleriesReducer from './galleriesReducer'
import galleryImagesReducer from './galleryImagesReducer'
import addNewGalleryImageReducer from './addNewGalleryImageReducer'
import updateGalleryImageReducer from './updateGalleryImageReducer'
import publicGalleryImagesReducer from './publicGalleryImagesReducer'
import teamReducer from './teamReducer'
import teamBinReducer from './teamBinReducer'
import createTeamMemberReducer from './createTeamMemberReducer'
import updateTeamMemberReducer from './updateTeamMemberReducer'
import publicTeamReducer from './publicTeamReducer'
import addNewShopGalleryImageReducer from './shop/addNewShopGalleryImageReducer'
import categoriesBinReducer from './shop/categoriesBinReducer'
import categoriesReducer from './shop/categoriesReducer'
import couponsBinReducer from './shop/couponsBinReducer'
import couponsReducer from './shop/couponsReducer'
import createCategoryReducer from './shop/createCategoryReducer'
import createCouponReducer from './shop/createCouponReducer'
import createShopReducer from './shop/createShopReducer'
import ordersReducer from './shop/ordersReducer'
import shopGalleryBinReducer from './shop/shopGalleryBinReducer'
import shopGalleryReducer from './shop/shopGalleryReducer'
import shopsBinReducer from './shop/shopsBinReducer'
import shopsReducer from './shop/shopsReducer'
import updateCategoryReducer from './shop/updateCategoryReducer'
import updateCouponReducer from './shop/updateCouponReducer'
import updateShopGalleryImageReducer from './shop/updateShopGalleryImageReducer'
import updateShopReducer from './shop/updateShopReducer'
import publicShopCategoriesReducer from './publicShopCategoriesReducer'
import publicShopProductsGalleryReducer from './publicShopProductsGalleryReducer'
import publicShopProductsReducer from './publicShopProductsReducer'
import publicShopProductsRelatedReducer from './publicShopProductsRelatedReducer'
import couponReducer from './couponReducer'
import cartReducer from './cartReducer'
import popupReducer from './popupReducer'
import customerAuthReducer from './customerAuthReducer'
import customerProfileReducer from './customerProfileReducer'
import checkoutReducer from './checkoutReducer'
import forgotPasswordReducer from './forgotPasswordReducer'
import forgotPasswordOtpReducer from './forgotPasswordOtpReducer'
import forgotPasswordResetReducer from './forgotPasswordResetReducer'
import paymentStatusReducer from './paymentStatusReducer'
import customerOrdersReducer from './customerOrdersReducer'
import currencyReducer from './currencyReducer'
import newsletterPopupReducer from './newsletterPopupReducer'

const persistConfig = {
    key: 'root',
    // storage,
    storage: new CookieStorage(Cookies),
    whitelist: ['authState', 'cookieState', 'cartState', 'customerAuthState', 'customerProfileState', 'checkoutState', 'couponState', 'paymentStatusReducer', 'newsletterPopupState']
}

const rootReducer = persistCombineReducers(persistConfig, {
    cartState: cartReducer,
    checkoutState: checkoutReducer,
    paymentStatusState: paymentStatusReducer,
    couponState: couponReducer,
    customerAuthState: customerAuthReducer,
    customerProfileState: customerProfileReducer,
    publicShopCategoriesState: publicShopCategoriesReducer,
    publicShopProductsGalleryState: publicShopProductsGalleryReducer,
    publicShopProductsState: publicShopProductsReducer,
    publicShopProductsRelatedState: publicShopProductsRelatedReducer,
    sendContactMailState: sendContactMailReducer,
    newsletterSubscriptionState: newsletterSubscriptionReducer,
    authState: authReducer,
    myAccountState: myAccountReducer,
    usersState: usersReducer,
    createUserState: createUserReducer,
    updateUserState: updateUserReducer,
    usersBinState: usersBinReducer,
    productsState: productsReducer,
    createProductState: createProductReducer,
    updateProductState: updateProductReducer,
    productsBinState: productsBinReducer,
    productGalleryState: productGalleryReducer,
    addNewProductGalleryImageState: addNewProductGalleryImageReducer,
    updateProductGalleryImageState: updateProductGalleryImageReducer,
    productGalleryBinState: productGalleryBinReducer,
    postsState: postsReducer,
    createPostState: createPostReducer,
    updatePostState: updatePostReducer,
    postsBinState: postsBinReducer,
    mediaState: mediaReducer,
    publicProductsState: publicProductsReducer,
    publicProductDetailsState: publicProductDetailsReducer,
    publicPostsState: publicPostsReducer,
    publicPostDetailsState: publicPostDetailsReducer,
    cookieState: cookieReducer,
    galleriesState: galleriesReducer,
    galleryImagesState: galleryImagesReducer,
    addNewGalleryImageState: addNewGalleryImageReducer,
    updateGalleryImageState: updateGalleryImageReducer,
    publicGalleryImagesState: publicGalleryImagesReducer,
    teamState: teamReducer,
    teamBinState: teamBinReducer,
    createTeamMemberState: createTeamMemberReducer,
    updateTeamMemberState: updateTeamMemberReducer,
    publicTeamState: publicTeamReducer,
    addNewShopGalleryImageState: addNewShopGalleryImageReducer,
    categoriesBinState: categoriesBinReducer,
    categoriesState: categoriesReducer,
    couponsBinState: couponsBinReducer,
    couponsState: couponsReducer,
    createCategoryState: createCategoryReducer,
    createCouponState: createCouponReducer,
    createShopState: createShopReducer,
    ordersState: ordersReducer,
    shopGalleryBinState: shopGalleryBinReducer,
    shopGalleryState: shopGalleryReducer,
    shopsBinState: shopsBinReducer,
    shopsState: shopsReducer,
    updateCategoryState: updateCategoryReducer,
    updateCouponState: updateCouponReducer,
    updateShopGalleryImageState: updateShopGalleryImageReducer,
    updateShopState: updateShopReducer,
    popupState: popupReducer,
    forgotPasswordState: forgotPasswordReducer,
    forgotPasswordOtpState: forgotPasswordOtpReducer,
    forgotPasswordResetState: forgotPasswordResetReducer,
    customerOrdersState: customerOrdersReducer,
    currencyState: currencyReducer,
    form: formReducer,
    newsletterPopupState: newsletterPopupReducer,
})

export default rootReducer