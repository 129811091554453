import React from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon
  } from "react-share";
import ScrolledIntoView from '../../ScrolledIntoView'

class SocialMediaShare extends React.Component {

    render(){
        return(           
            <section className={`_section _social-media-sharing-section`}>
                <div className="_box">
                    <div className="_column">
                        <ScrolledIntoView>
                            <div className="_sharing-panel">
                                <p style={{
                                    marginTop: '0'
                                }}>If you enjoyed reading this story,<br /> then we’d love it if you would <strong>share it!</strong></p>
                                <div style={{display: 'flex', justifyContent: 'center', columnGap: '30px'}}>
                                    <FacebookShareButton 
                                        url={window.location}
                                        quote={this.props.data.headline}
                                        hashtag={this.props.data.hashtag}
                                    >
                                        <FacebookIcon size={35} round iconFillColor="#ffffff" bgStyle={{fill: '#333333'}} />
                                    </FacebookShareButton>
                                    <TwitterShareButton 
                                        url={window.location}
                                        title={this.props.data.headline}
                                        hashtags={[this.props.data.hashtag]}
                                    >
                                        <TwitterIcon size={35} round iconFillColor="#ffffff" bgStyle={{fill: '#333333'}} />
                                    </TwitterShareButton>
                                    <EmailShareButton
                                        url={window.location}
                                        subject={this.props.data.headline}
                                        body={this.props.summary}
                                    >
                                        <EmailIcon size={35} round iconFillColor="#ffffff" bgStyle={{fill: '#333333'}} />
                                    </EmailShareButton>
                                </div>
                            </div>
                        </ScrolledIntoView>
                    </div>
                </div>
            </section>
        )
    }
}

export default SocialMediaShare