import _ from 'lodash'
import {
    FETCH_PUBLIC_PRODUCTS,
} from '../actions/types'

const initialState = {}

const publicProductsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PRODUCTS:
            return {
                ...state, ..._.mapKeys(action.payload, 'slug')
            }
        default:
            return state
    }
}

export default publicProductsReducer