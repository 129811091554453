import React, { Component } from "react";
import Slider from "react-slick";
import {BASE_PATH} from '../../paths'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ShopProductGallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nav1: null,
      nav2: null
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  next() {
    this.slider1.slickNext();
    this.slider2.slickNext();
  }

  previous() {
    this.slider1.slickPrev();
    this.slider2.slickPrev();
  }

  renderArrows = () => {
    return (
      <div className="_shop-gallery-arrows">
        <button className="_arrow _arrow-prev" onClick={this.previous}><span /></button>
        <button className="_arrow _arrow-next" onClick={this.next}><span /></button>
      </div>
    )
  }

  renderImage = () => {
    return this.props.images.map(({path}, index) => {
      return (
        <div key={index}>
          <div className="_shop-gallery-image-display" style={{backgroundImage: `url(${BASE_PATH+path})`}} />
        </div>
      )
    })
  }

  renderGallery = () => {
    return this.props.images.map(({path}, index) => {
      return (
        <div key={index}>
          <div className="_shop-gallery-side-images-item" style={{backgroundImage: `url(${BASE_PATH+path})`}} />
        </div>
      )
    })
  }

  render() {

    const slider1Settings = {
      infinite: false,
      asNavFor: this.state.nav2,
      ref: slider => (this.slider1 = slider),
      arrows: false
    }

    const slider2Settings = {
      infinite: false,
      asNavFor:this.state.nav1,
      ref:slider => (this.slider2 = slider),
      slidesToShow:5,
      swipeToSlide:false,
      focusOnSelect:true,
      vertical:true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            vertical:false,
            swipeToSlide:true,
          }
        }
      ]
    }
    
    return (
      <div className="_shop-product-gallery">
        <div className="_display">
          <Slider
            {...slider1Settings}
          >
            {this.renderImage()}
          </Slider>
          {this.props.images.length > 1 && this.renderArrows()}
        </div>
        <div className="_as-for-nav">
          <Slider
            {...slider2Settings}
          >
            {this.props.images.length > 1 && this.renderGallery()}
          </Slider>
        </div>
      </div>
    );
  }
}

export default ShopProductGallery