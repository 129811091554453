import React from 'react'

const CloseIcon = ({onClick}) => {
    return(
        <button className="_close-icon" onClick={onClick}>
            <div>
                <span />
                <span />
            </div>
        </button>
    )
}

export default CloseIcon