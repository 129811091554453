import {
    SET_CURRENCY
} from '../actions/types'

const initialState = {
    currency: 'GBP'
}

const currencyReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CURRENCY:
            return {
                currency: action.payload
            }
        default:
            return state
    }
}

export default currencyReducer