import {
  POPUP_SHOW,
  POPUP_HIDE
} from '../actions/types'

const initialState = {
    message: null
}

const popupReducer = (state = initialState, action) => {
    switch(action.type){
        case POPUP_SHOW:
            return {
                message: action.message,
                buttonText: action.buttonText ? action.buttonText : null,
                children: action.children ? action.children : null
            }
        
          case POPUP_HIDE:
            return initialState
            
        default:
            return state
    }
}

export default popupReducer