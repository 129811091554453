import admin from '../apis/admin'

import {
  GET_COUPON,
  GET_COUPON_FAILED,
  REMOVE_COUPON
} from './types'

export const getCoupon = code => async dispatch => {
    
  try {

      const response = await admin.get('shop/coupon/' + code)

      dispatch({
          type: GET_COUPON,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: GET_COUPON_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const removeCoupon = () => {
  return (dispatch) => {
      dispatch({
          type: REMOVE_COUPON
      })
  }
}