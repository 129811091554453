import {
  FETCH_PUBLIC_SHOP_PRODUCT_RELATED
} from '../actions/types'

const initialState = {}

const publicShopProductsRelatedReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_SHOP_PRODUCT_RELATED:

            if(!action.productSlug)
            return {
              ...state, ['may_also_like']: action.payload.data
            }
            
            return {
              ...state, [action.productSlug]: action.payload.data
            }

        default:
            return state
    }
}

export default publicShopProductsRelatedReducer